var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "employer-basic-management" },
    [
      _c(
        "el-form",
        {
          key: "employerBasicForm",
          ref: "employerBasicForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            "label-position": "right",
            model: _vm.employerBasicForm,
            rules: _vm.employerBasicFormRules
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "基本信息" }
          }),
          _c("div", { staticStyle: { "border-bottom": "1px solid #D5D9E0" } }, [
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "单位名称：",
                      prop: "employerBasic.unitName"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "300px !important" },
                      attrs: { maxlength: "100", placeholder: "" },
                      model: {
                        value: _vm.employerBasicForm.employerBasic.unitName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "unitName",
                            $$v
                          )
                        },
                        expression: "employerBasicForm.employerBasic.unitName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "社会信用代码：",
                      prop: "employerBasic.creditCode"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "200px !important" },
                      attrs: { maxlength: "18", placeholder: "", disabled: "" },
                      model: {
                        value: _vm.employerBasicForm.employerBasic.creditCode,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "creditCode",
                            $$v
                          )
                        },
                        expression: "employerBasicForm.employerBasic.creditCode"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "95px",
                      label: "单位简称：",
                      prop: "employerBasic.unitShortName"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "146px !important" },
                      attrs: { maxlength: "6", placeholder: "", clearable: "" },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic.unitShortName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "unitShortName",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.unitShortName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "95px",
                      label: "法定代表人：",
                      prop: "employerBasic.legalPerson"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "130px !important" },
                      attrs: {
                        maxlength: "50",
                        placeholder: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.employerBasicForm.employerBasic.legalPerson,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "legalPerson",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.legalPerson"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "170px",
                      label: "法定代表人联系电话：",
                      prop: "employerBasic.legalPersonPhone"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "130px !important" },
                      attrs: {
                        maxlength: "15",
                        placeholder: "",
                        clearable: ""
                      },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic.legalPersonPhone,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "legalPersonPhone",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.legalPersonPhone"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "95px",
                      label: "主要负责人：",
                      prop: "employerBasic.contactPerson"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "297px !important" },
                      attrs: {
                        maxlength: "50",
                        placeholder: "",
                        clearable: ""
                      },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic.contactPerson,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "contactPerson",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.contactPerson"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "170px",
                      label: "主要负责人联系电话：",
                      prop: "employerBasic.contact"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "227px !important" },
                      attrs: {
                        maxlength: "15",
                        placeholder: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.employerBasicForm.employerBasic.contact,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "contact",
                            $$v
                          )
                        },
                        expression: "employerBasicForm.employerBasic.contact"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "企业规模：",
                      prop: "employerBasic.enterpriseScale"
                    }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "zwx-radio-group",
                        model: {
                          value:
                            _vm.employerBasicForm.employerBasic.enterpriseScale,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.employerBasicForm.employerBasic,
                              "enterpriseScale",
                              $$v
                            )
                          },
                          expression:
                            "employerBasicForm.employerBasic.enterpriseScale"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 1 } },
                          [_vm._v("大")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 2 } },
                          [_vm._v("中")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 3 } },
                          [_vm._v("小")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 4 } },
                          [_vm._v("微")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "所属行业：",
                      prop: "employerBasic.industryName"
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "zwx-input zwx-occ-category-input",
                            staticStyle: { width: "300px !important" },
                            attrs: {
                              readonly: "readonly",
                              placeholder: "",
                              "read-only": true
                            },
                            on: { focus: _vm.msDeclareInfoCategoryDialog },
                            model: {
                              value:
                                _vm.employerBasicForm.employerBasic
                                  .industryName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.employerBasicForm.employerBasic,
                                  "industryName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "employerBasicForm.employerBasic.industryName"
                            }
                          },
                          [
                            _c("template", { slot: "append" }, [
                              _c("span", [
                                _c("i", { staticClass: "el-icon-search" })
                              ])
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "经济类型：",
                      prop: "employerBasic.economicCode"
                    }
                  },
                  [
                    _c("cascader-single", {
                      ref: "cascader",
                      staticClass: "cascader-single-economic",
                      attrs: {
                        simpleCodeList:
                          _vm.msEmployerBasicFormExp.economicSimpleCodeList,
                        expandTrigger: "hover",
                        codeTypeCode: "1015"
                      },
                      on: { change: _vm.economicChange },
                      model: {
                        value: _vm.employerBasicForm.employerBasic.economicCode,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "economicCode",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.economicCode"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "注册地区：",
                      prop: "employerBasic.registerZone"
                    }
                  },
                  [
                    _c("zwx-select-area", {
                      ref: "registerZoneArea",
                      staticClass: "cascader-area-register-zone",
                      attrs: {
                        zoneCode12From:
                          _vm.msEmployerBasicFormExp.registerZoneFrom,
                        zoneTypeMax: "0",
                        zoneTypeMin: "4",
                        showFullName: true
                      },
                      on: { change: _vm.registerZoneChange }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "注册地址：",
                      prop: "employerBasic.registerAddr"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "494px !important" },
                      attrs: {
                        maxlength: "400",
                        placeholder: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.employerBasicForm.employerBasic.registerAddr,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "registerAddr",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.registerAddr"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "经营地区：",
                      prop: "employerBasic.bizZone"
                    }
                  },
                  [
                    _c("zwx-select-area", {
                      ref: "bizZoneArea",
                      staticClass: "cascader-area-biz-zone",
                      attrs: {
                        zoneCode12From: _vm.msEmployerBasicFormExp.bizZoneFrom,
                        zoneTypeMax: "0",
                        zoneTypeMin: "4",
                        showFullName: true
                      },
                      on: { change: _vm.bizZoneChange }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "msmap",
                    attrs: {
                      "label-width": "110px",
                      label: "作业场所地址：",
                      prop: "employerBasic.workplaceAddr"
                    }
                  },
                  [
                    _c("baidu-map-dialog", {
                      ref: "mapCoverDialogRef",
                      attrs: {
                        lng: _vm.employerBasicForm.employerBasic.lng,
                        lat: _vm.employerBasicForm.employerBasic.lat,
                        fullZoneName: _vm.fullZoneName
                      },
                      on: {
                        submit: _vm.employerMapDialogSubmit,
                        msNotify: _vm.baiduMapMsNotify
                      },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic.workplaceAddr,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "workplaceAddr",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.workplaceAddr"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "138px",
                      label: "职业卫生管理人员：",
                      prop: "employerBasic.healthManagement"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      attrs: {
                        maxlength: "50",
                        placeholder: "",
                        clearable: ""
                      },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic.healthManagement,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "healthManagement",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.healthManagement"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "联系电话：",
                      prop: "employerBasic.managementPhone"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "157px !important" },
                      attrs: {
                        maxlength: "15",
                        placeholder: "",
                        clearable: ""
                      },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic.managementPhone,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "managementPhone",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.managementPhone"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "base-headline",
            {
              staticClass: "no-top",
              class: {
                "no-bottom": !_vm.employerBasicForm.employerBasic
                  .ifClassifiedUnit
              },
              attrs: { title: "主要产品" }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: {
                    icon: "el-icon-plus",
                    disabled:
                      _vm.employerBasicForm.employerBasic.ifClassifiedUnit
                  },
                  on: { click: _vm.addProduct }
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "14px" },
                  on: { change: _vm.updateUnit },
                  model: {
                    value: _vm.employerBasicForm.employerBasic.ifClassifiedUnit,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.employerBasicForm.employerBasic,
                        "ifClassifiedUnit",
                        $$v
                      )
                    },
                    expression:
                      "employerBasicForm.employerBasic.ifClassifiedUnit"
                  }
                },
                [_vm._v("军工等涉密企业")]
              ),
              _c(
                "div",
                {
                  staticClass: "ms-el-message ms-el-message--warning",
                  staticStyle: {
                    display: "inline-block",
                    "margin-left": "10px"
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(
                    " 非生产型企业，如医院、饭店等，主要产品项名称及计量单位可填“无”，年产量填“0”。 "
                  )
                ]
              )
            ],
            1
          ),
          !_vm.employerBasicForm.employerBasic.ifClassifiedUnit
            ? _c(
                "el-table",
                {
                  key: "productListTable",
                  staticClass: "zwx-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "productListTable",
                    "row-key": "rid",
                    data: _vm.employerBasicForm.productList,
                    stripe: "",
                    border: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", type: "index", width: "80" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      width: "350",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop:
                                      "productList." +
                                      scope.$index +
                                      ".productName",
                                    rules:
                                      _vm.employerBasicFormRules.productName
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "zwx-input",
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "50",
                                      clearable: ""
                                    },
                                    model: {
                                      value: scope.row.productName,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "productName", $$v)
                                      },
                                      expression: "scope.row.productName"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4228498394
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dosage",
                      label: "年产量",
                      width: "180",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop:
                                      "productList." +
                                      scope.$index +
                                      ".annualOutput",
                                    rules:
                                      _vm.employerBasicFormRules.annualOutput
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "zwx-input",
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "50",
                                      clearable: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.$validate.nonnegativeDouble(
                                          _vm.$data["employerBasicForm"][
                                            "productList"
                                          ][scope.$index],
                                          "annualOutput",
                                          -1,
                                          2
                                        )
                                      }
                                    },
                                    model: {
                                      value: scope.row.annualOutput,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "annualOutput", $$v)
                                      },
                                      expression: "scope.row.annualOutput"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      807353286
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "unit",
                      label: "计量单位",
                      width: "180",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop:
                                      "productList." + scope.$index + ".unit",
                                    rules: _vm.employerBasicFormRules.unit
                                  }
                                },
                                [
                                  _c("el-autocomplete", {
                                    staticClass: "zwx-input",
                                    attrs: {
                                      "value-key": "codeName",
                                      "fetch-suggestions":
                                        _vm.unitAutocompleteQuery,
                                      maxlength: 10,
                                      clearable: ""
                                    },
                                    model: {
                                      value: scope.row.unit,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "unit",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "scope.row.unit"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      886789471
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "header-align": "left",
                      align: "left",
                      "min-width": "80"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass:
                                    "zwx-button zwx-button-text-26 zwx-button-danger",
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delProduct(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v(" 删除 ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2802346820
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "人员基本情况" }
          }),
          _c("div", [
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "115px",
                      label: "在册职工总数：",
                      prop: "employerBasic.totalStaffNum"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "100px !important" },
                      attrs: { maxlength: "9", placeholder: "" },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic.totalStaffNum,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "totalStaffNum",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.totalStaffNum"
                      }
                    }),
                    _c("div", { staticClass: "person-unit" }, [_vm._v("人")])
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "183px",
                      label: "在册接害总人数：",
                      prop: "employerBasic.workerNum"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "100px !important" },
                      attrs: { maxlength: "9", placeholder: "" },
                      model: {
                        value: _vm.employerBasicForm.employerBasic.workerNum,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "workerNum",
                            $$v
                          )
                        },
                        expression: "employerBasicForm.employerBasic.workerNum"
                      }
                    }),
                    _c("div", { staticClass: "person-unit" }, [_vm._v("人")])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "115px",
                      label: "外委人员总数：",
                      prop: "employerBasic.outsourcingNum"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "100px !important" },
                      attrs: { maxlength: "9", placeholder: "" },
                      on: {
                        input: function($event) {
                          return _vm.$validate.nonnegativeInteger(
                            _vm.$data["employerBasicForm"]["employerBasic"],
                            "outsourcingNum"
                          )
                        }
                      },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic.outsourcingNum,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "outsourcingNum",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.outsourcingNum"
                      }
                    }),
                    _c("div", { staticClass: "person-unit" }, [_vm._v("人")])
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "185px",
                      label: "外委接害人数：",
                      prop: "employerBasic.outsourcingVictimsNum"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "100px !important" },
                      attrs: { maxlength: "9", placeholder: "" },
                      on: {
                        input: function($event) {
                          return _vm.$validate.nonnegativeInteger(
                            _vm.$data["employerBasicForm"]["employerBasic"],
                            "outsourcingVictimsNum"
                          )
                        }
                      },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic
                            .outsourcingVictimsNum,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "outsourcingVictimsNum",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.outsourcingVictimsNum"
                      }
                    }),
                    _c("div", { staticClass: "person-unit" }, [_vm._v("人")])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "170px",
                      label: "接害总人数（含外委）："
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.totalVictimsNum) + " 人")])]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "173px",
                      label: "累计确诊职业病人数：",
                      prop: "employerBasic.confirmedDiseaseNum"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "100px !important" },
                      attrs: { maxlength: "9", placeholder: "" },
                      on: {
                        input: function($event) {
                          return _vm.$validate.nonnegativeInteger(
                            _vm.$data["employerBasicForm"]["employerBasic"],
                            "confirmedDiseaseNum"
                          )
                        }
                      },
                      model: {
                        value:
                          _vm.employerBasicForm.employerBasic
                            .confirmedDiseaseNum,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.employerBasicForm.employerBasic,
                            "confirmedDiseaseNum",
                            $$v
                          )
                        },
                        expression:
                          "employerBasicForm.employerBasic.confirmedDiseaseNum"
                      }
                    }),
                    _c("div", { staticClass: "person-unit" }, [_vm._v("人")])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("base-headline", { attrs: { title: "账号信息" } }),
          _c(
            "div",
            { staticStyle: { "padding-top": "16px", "padding-left": "16px" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "82px",
                        label: "姓名：",
                        prop: "employerBasic.userName"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "159px !important" },
                        attrs: {
                          maxlength: "50",
                          placeholder: "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.employerBasicForm.employerBasic.userName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.employerBasicForm.employerBasic,
                              "userName",
                              $$v
                            )
                          },
                          expression: "employerBasicForm.employerBasic.userName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "87px",
                        label: "账号：",
                        prop: "employerBasic.userNo"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "200px !important" },
                        attrs: {
                          onkeyup:
                            "value=value.replace(/[^\\a-\\z\\A-\\Z0-9]/g,'')",
                          maxlength: "50",
                          placeholder: "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.employerBasicForm.employerBasic.userNo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.employerBasicForm.employerBasic,
                              "userNo",
                              $$v
                            )
                          },
                          expression: "employerBasicForm.employerBasic.userNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "95px",
                        label: "手机号：",
                        prop: "employerBasic.mobileTel"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "157px !important" },
                        attrs: {
                          maxlength: "20",
                          placeholder: "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.employerBasicForm.employerBasic.mobileTel,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.employerBasicForm.employerBasic,
                              "mobileTel",
                              $$v
                            )
                          },
                          expression:
                            "employerBasicForm.employerBasic.mobileTel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "82px",
                        label: "证件类型：",
                        prop: "employerBasic.cardType"
                      }
                    },
                    [
                      _c("cascader-single", {
                        ref: "idTypeCascader",
                        staticClass: "cascader-single-common",
                        attrs: {
                          simpleCodeList:
                            _vm.msEmployerBasicFormExp.idTypeSimpleCodeList,
                          expandTrigger: "hover",
                          codeTypeCode: "1003"
                        },
                        model: {
                          value: _vm.employerBasicForm.employerBasic.cardType,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.employerBasicForm.employerBasic,
                              "cardType",
                              $$v
                            )
                          },
                          expression: "employerBasicForm.employerBasic.cardType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "87px",
                        label: "证件号码：",
                        prop: "employerBasic.idc"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "200px !important" },
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.employerBasicForm.employerBasic.idc,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.employerBasicForm.employerBasic,
                              "idc",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "employerBasicForm.employerBasic.idc"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm.childTbEmployerExpList.length != 0
        ? _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "组织架构管理" }
          })
        : _vm._e(),
      _vm.childTbEmployerExpList.length != 0
        ? _c(
            "div",
            { staticClass: "organization" },
            _vm._l(_vm.childTbEmployerExpList, function(item) {
              return _c(
                "div",
                {
                  key: item.rid,
                  staticClass: "organization-item",
                  on: {
                    click: function($event) {
                      return _vm.jumpMenu(item.unitUid)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("div", { staticClass: "title-unit" }, [
                      _c("span", { staticStyle: { color: "#535E6F" } }, [
                        _vm._v(_vm._s(item.unitName))
                      ]),
                      item.enterpriseScale
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.enterpriseScaleList[item.enterpriseScale]
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticStyle: { color: "#535E6F" } }, [
                      _vm._v(_vm._s(item.creditCode))
                    ])
                  ]),
                  _c("div", { staticClass: "body" }, [
                    _c("div", { staticClass: "body-row" }, [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/ico/info/type.png"),
                          alt: "",
                          srcset: ""
                        }
                      }),
                      _c("span", [
                        _vm._v("行业分类：" + _vm._s(item.industryName))
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "body-row",
                        staticStyle: { "padding-left": "19px" }
                      },
                      [
                        _c("span", [
                          _vm._v("经济类型：" + _vm._s(item.economicName))
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "body-row" }, [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/ico/info/ic_dizhi.png"),
                          alt: "",
                          srcset: ""
                        }
                      }),
                      _c("span", [
                        _vm._v("注册地址：" + _vm._s(item.registerAddr))
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "body-row",
                        staticStyle: { "padding-left": "19px" }
                      },
                      [
                        _c("span", [
                          _vm._v("经营地址：" + _vm._s(item.workplaceAddr))
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "body-row" }, [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/ico/info/ic_faren.png"),
                          alt: "",
                          srcset: ""
                        }
                      }),
                      _c("span", [
                        _vm._v(" " + _vm._s(item.legalPerson) + " "),
                        item.contact != undefined
                          ? _c("i", [
                              _vm._v(
                                "（" +
                                  _vm._s(
                                    _vm.$zwxSm.sm4JsDecrypt(item.contact)
                                  ) +
                                  "）"
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary", disabled: _vm.saveButtonDisabled },
              on: { click: _vm.saveEmployerinfo }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "factorSelectDialogRef",
          staticClass: "factor-select-dialog",
          attrs: {
            title: "添加危害因素",
            pagination: true,
            currentPage: _vm.factorDialog.factorSelectForm.currentPage,
            pageSize: _vm.factorDialog.pageSize,
            total: _vm.factorDialog.total
          },
          on: {
            determine: _vm.confirmFactorDialog,
            cancel: _vm.cancelFactorDialog,
            close: _vm.closeFactorDialog,
            currentPage: _vm.factorDialogSearchInit
          }
        },
        [
          _c(
            "el-form",
            {
              key: "factorSelectForm",
              staticClass: "zwx-form edit-form",
              attrs: {
                model: _vm.factorDialog.factorSelectForm,
                "label-position": "right"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "危害因素名称：", "label-width": "98px" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "150px" },
                        attrs: {
                          placeholder: "请输入危害因素名称",
                          clearable: ""
                        },
                        model: {
                          value: _vm.factorDialog.factorSelectForm.hazardsName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.factorDialog.factorSelectForm,
                              "hazardsName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "factorDialog.factorSelectForm.hazardsName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-icontext-28",
                      on: {
                        click: function($event) {
                          return _vm.factorDialogSearchInit(1)
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "20px" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "zwx-table",
                  attrs: {
                    "row-key": "rid",
                    data: _vm.factorDialog.factorList,
                    "highlight-current-row": "",
                    stripe: "",
                    border: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "选择",
                      width: "80",
                      "header-align": "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c("el-checkbox", {
                                  on: {
                                    change: function($event) {
                                      return _vm.declareHazardSelect0(scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.checked0,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "checked0", $$v)
                                    },
                                    expression: "scope.row.checked0"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "factor0.hazardsName",
                      label: "危害因素名称",
                      "header-align": "left",
                      align: "left",
                      "min-width": "400"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "选择",
                      width: "80",
                      "header-align": "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _vm.$zwxBase.verifyIsNotBlank(scope.row.factor1)
                                  ? _c("el-checkbox", {
                                      on: {
                                        change: function($event) {
                                          return _vm.declareHazardSelect1(
                                            scope.row
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.checked1,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "checked1", $$v)
                                        },
                                        expression: "scope.row.checked1"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "factor1.hazardsName",
                      label: "危害因素名称",
                      "header-align": "left",
                      align: "left",
                      "min-width": "400"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("industry-select", {
        ref: "msDeclareComponentCategory",
        attrs: { ifValidateIfLeaf: true, interfaceName: "/basic/info" },
        on: { change: _vm.msDeclareComponentCategoryChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }