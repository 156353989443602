<template>
  <div class="ms-training-management-detail">
    <base-tab :titles="tabTitles" v-model="activeName" @changeTab="activeChange"></base-tab>
    <div v-if="activeName == 1" style="position: absolute; top: 3px; right: 10px">
      <el-button v-if="ifShowBm && ifShowApp" class="zwx-button zwx-button-32" @click="signInQrCodeShow(1, 1)">小程序报名码展示</el-button>
      <el-button v-if="ifShowBm && ifShowH5" class="zwx-button zwx-button-32" type="primary" @click="signInQrCodeShow(1)">公众号报名码展示</el-button>
      <el-button v-if="ifShowQd && ifShowOff" class="zwx-button zwx-button-32" @click="signInQrCodeShow(2)">签到码展示</el-button>
    </div>

    <!-- 班级信息 -->
    <div v-show="activeName == 1">
      <div class="detail-title">
        {{ trainingActivityListForm.trainingActivityName }}
      </div>
      <div class="base-info-wrapper">
        <div class="base-info">
          <div class="detail-row">
            <div class="detail-item">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">主办单位：</span>
              </div>
              <div class="detail-info-txt">{{ userInfo.fkByUnitId.unitName }}</div>
            </div>
            <div class="detail-item">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">开班最少人数：</span>
              </div>
              <div class="detail-info-txt">{{ trainingActivityListForm.leastNum ? trainingActivityListForm.leastNum + '人' : '0人' }}</div>
            </div>
          </div>
          <div class="detail-row">
            <div class="detail-item">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">班主任：</span>
              </div>
              <div class="detail-info-txt">{{ trainingActivityListForm.linkName || '-' }}</div>
            </div>
            <div class="detail-item">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">联系方式：</span>
              </div>
              <div class="detail-info-txt">{{ $zwxSm.sm4JsDecrypt(trainingActivityListForm.linkTel) || '-' }}</div>
            </div>
          </div>
          <div class="detail-row">
            <div class="detail-item">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">报名时间：</span>
              </div>
              <div class="detail-info-txt">{{ trainingActivityListForm.registStartTime | time }} 至 {{ trainingActivityListForm.registEndTime | time }}</div>
            </div>
            <div class="detail-item">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">最大报名人数：</span>
              </div>
              <div class="detail-info-txt">{{ trainingActivityListForm.mostNum ? trainingActivityListForm.mostNum + '人' : '0人' }}</div>
            </div>
          </div>
          <!-- <div class="detail-row">
          <div class="detail-item">
            <div class="title">
              <span class="title-icon"></span>
              <span class="title-txt">培训内容：</span>
            </div>
            <div class="detail-info-txt">{{ trainingActivityListForm.ifOnLineCourse ? (!trainingActivityListForm.ifOffLineTraining && !trainingActivityListForm.ifExam && !trainingActivityListForm.ifCertificate ? '线上课程' : '线上课程、') : '' }}{{ trainingActivityListForm.ifOffLineTraining ? '线下培训、' : '' }}{{ trainingActivityListForm.ifExam ? '培训考试、' : '' }}{{ trainingActivityListForm.ifCertificate ? '培训发证' : '' }}</div>
          </div>
        </div> -->
          <div class="detail-row">
            <div class="detail-item">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">线上课程有效期：</span>
              </div>
              <div class="detail-info-txt">{{ trainingActivityListForm.courseStartTime | time }} 至 {{ trainingActivityListForm.courseEndTime | time }}</div>
            </div>
            <div class="detail-item" v-if="ifShowOff">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">线下培训时间：</span>
              </div>
              <div class="detail-info-txt">{{ trainingActivityListForm.offLineStartTime | time }} 至 {{ trainingActivityListForm.offLineEndTime | time }}</div>
            </div>
          </div>

          <div class="detail-row">
            <div class="detail-item" v-if="ifShowOff">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">线下培训地点：</span>
              </div>
              <div class="detail-info-txt">{{ trainingActivityListForm.offLineTrainingAddr }}</div>
            </div>
            <div class="detail-item" v-if="trainingActivityListForm.examType == 2">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">考试地点：</span>
              </div>
              <div class="detail-info-txt">{{ trainingActivityListForm.examAddr }}</div>
            </div>
          </div>

          <div class="detail-row">
            <div class="detail-item">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">是否推荐展示：</span>
              </div>
              <div class="detail-info-txt">{{ trainingActivityListForm.recommendDisplay ? '是' : '否' }}</div>
            </div>
          </div>
          <div v-if="unitNature == 10180003" class="detail-row">
            <div class="detail-item" style="width: 800px;">
              <div class="title" style="width: 90px;display: flex;align-items: center">
                <span class="title-icon"></span>
                <span class="title-txt">注意事项：</span>
              </div>
              <div class="detail-info-txt" style="flex:1">{{ trainingActivityListForm.mattersAttention }}</div>
            </div>
          </div>
          <div v-if="unitNature == 10180003" class="detail-row">
            <div class="detail-item" style="width: 800px;">
              <div class="title" style="width: 90px;display: flex;align-items: center">
                <span class="title-icon"></span>
                <span class="title-txt">备注内容：</span>
              </div>
              <div class="detail-info-txt" style="flex:1">{{ trainingActivityListForm.remark }}</div>
            </div>
          </div>
          <div class="detail-row" v-if="unitNature == 10180003">
            <div class="detail-item">
              <div class="title" style="display: flex;align-items: center">
                <span class="title-icon"></span>
                <span class="title-txt">附件：</span>
              </div>
              <div class="detail-info-txt">
                <el-button v-if="trainingActivityListForm.filePath" class="zwx-button zwx-button-text-20" @click="openFileDownlaod(trainingActivityListForm.fileName, trainingActivityListForm.filePath)">{{ trainingActivityListForm.fileName }}</el-button>
                <span v-else>/</span>
              </div>
            </div>
          </div>
        </div>
        <div class="base-info">
          <div class="detail-row">
            <div class="detail-item" v-if="trainingActivityListForm.activityCoverPath">
              <div class="title">
                <span class="title-icon"></span>
                <span class="title-txt">培训班封面：</span>
              </div>
              <div class="detail-info-txt">
                <el-image
                  :src="`${file}${trainingActivityListForm.activityCoverPath}`"
                  fit="contain"
                  :preview-src-list="[`${file}${trainingActivityListForm.activityCoverPath}`]"
                  :style="{
                    maxWidth: '140px',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 课程配置 -->
      <base-headline title="课程配置" class="no-bottom"></base-headline>
      <!-- 列表展示部分 -->
      <el-table class="zwx-table" row-key="rid" :data="trainingActivityListForm.courseExpList" stripe tooltip-effect="light">
        <el-table-column prop="traineeTypeName" label="学员类型" width="200" header-align="center" align="center"></el-table-column>
        <el-table-column prop="trainingTypeName" label="培训类型" width="100" header-align="center" align="center"></el-table-column>
        <el-table-column prop="courseName" label="课程选择" width="400" header-align="center" align="left"></el-table-column>
        <el-table-column prop="onLineClassHoure" label="线上课程学时" width="120" header-align="center" align="center"></el-table-column>
        <el-table-column prop="offLineClassHoure" label="线下培训学时" width="120" header-align="center" align="center"></el-table-column>
        <el-table-column prop="registType" label="报名类型" width="120" header-align="center" align="center">
          <template slot-scope="scope">{{ scope.row.registType == 1 ? '非公开' : '公开' }}</template>
        </el-table-column>
        <el-table-column prop="expiryDate" label="报名价格（元）" v-if="unitNature != 10180003"  width="140" header-align="center" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.registType == 1">-</span>
            <span v-else>{{ Number(scope.row.registPrice).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="courseName" label="操作" min-width="100" header-align="left" align="left">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26" type="text" @click="showCourseInfo(scope.row)">
              课程详情
              <i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 学员管理 -->
    <div v-show="activeName == 2">
      <div class="student-management">
        <el-form class="zwx-form" key="studentManagementForm" ref="studentManagementFormRef" :model="studentManagementForm" label-position="right" @submit.native.prevent label-width="100px">
          <el-form-item label="所属单位：">
            <el-input class="zwx-input" v-model.trim="studentManagementForm.unitName" style="width: 200px !important" placeholder="" clearable maxlength="50" />
          </el-form-item>
          <el-form-item label="学员姓名：">
            <el-input class="zwx-input" v-model.trim="studentManagementForm.userName" style="width: 200px !important" placeholder="" clearable maxlength="50" />
          </el-form-item>
          <el-form-item label="学员类别：">
            <el-select v-model="studentManagementForm.traineeType" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper" clearable @change="resetFilterOptions" style="width: 200px !important">
              <el-option v-for="item in targetPopulationOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
            </el-select>
          </el-form-item>
          <div>
            <el-form-item label="培训类型：">
              <el-select v-model="studentManagementForm.trainingType" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper" clearable style="width: 200px !important">
                <el-option v-for="item in trainingTypeFilterOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报名类型：">
              <el-select v-model="studentManagementForm.registWay" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper" style="width: 200px !important">
                <el-option label="不限" :value="''"></el-option>
                <el-option label="自主报名" :value="1"></el-option>
                <el-option label="管理员报名" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="线上课程进度：">
              <el-select v-model="studentManagementForm.studyState" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper" style="width: 200px !important">
                <el-option label="不限" :value="''"></el-option>
                <el-option label="未开始" :value="0"></el-option>
                <el-option label="进行中" :value="1"></el-option>
                <el-option label="已完成" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <el-form-item label="线下签到情况：">
            <el-select v-model="studentManagementForm.ifSignIn" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper" style="width: 200px !important">
              <el-option label="不限" :value="''"></el-option>
              <el-option label="未签到" :value="0"></el-option>
              <el-option label="已签到" :value="12"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="所属地区：">
          <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChangeForm" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :clearable="false" />
        </el-form-item>
          <!-- <el-form-item label="考试情况：">
            <el-select v-model="studentManagementForm.examResultInt" placeholder="请选择" class="zwx-select zwx-select-student" style="width: 200px !important">
              <el-option label="不限" :value="''"></el-option>
              <el-option label="待考试" :value="3"></el-option>
              <el-option label="考试通过" :value="5"></el-option>
              <el-option label="考试未通过" :value="4"></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
        <div class="independent-button-row">
          <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryTrainingCourse(1)">查询</el-button>
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addOrEditThemeActivityList">添加</el-button>
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" @click="staffInfoImport">导入</el-button>
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-download" @click="reportDownload">导出</el-button>
        </div>
        <!-- 列表展示部分 -->
        <el-table class="zwx-table" row-key="rid" :data="studenTableList" border stripe tooltip-effect="light">
          <el-table-column prop="unitName" label="所属单位" width="280" header-align="center" align="left" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="userName" label="姓名" width="120" header-align="center" align="center"></el-table-column>
          <el-table-column prop="cardTypeName" label="证件类型" width="150" header-align="center" align="center"></el-table-column>
          <el-table-column prop="cardNo" label="证件号" width="180" header-align="center" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.idc }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="mobileTel" label="手机号" width="120" header-align="center" align="center">
            <template slot-scope="scope">
              <span>{{ $zwxSm.sm4JsDecrypt(scope.row.phoneNumber) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="traineeTypeName" label="学员类别" width="160" header-align="center" align="center"></el-table-column>
          <el-table-column prop="trainingTypeName" label="培训类型" width="100" header-align="center" align="center"></el-table-column>
          <el-table-column prop="trainingActivityName" label="线上课程进度" width="120" header-align="center" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.alreadyClassHoure }}/{{ scope.row.allRequiredClassHoure }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ifSignIn" label="线下培训签到" width="120" header-align="center" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.ifSignIn === 0">未签到</span>
              <span class="zwx-circle-text zwx-circle-text:before zwx-circle-complete zwx-circle-complete::before" v-else-if="scope.row.ifSignIn === 1">已签到</span>
              <span class="zwx-circle-text zwx-circle-text:before zwx-circle-underway zwx-circle-underway:before" v-else>补签</span>
            </template>
          </el-table-column>
          <el-table-column prop="fullName" label="所属地区" width="160" header-align="center" align="center"></el-table-column>

          <!-- <el-table-column prop="examResultStr" label="考试情况" width="120" header-align="center" align="center"></el-table-column> -->
          <el-table-column prop="registWay" label="报名类型" width="100" header-align="center" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.registWay == 1">自主报名</span>
              <span v-if="scope.row.registWay == 2">管理员报名</span>
            </template>
          </el-table-column>
          <el-table-column prop="registStatus" label="报名状态" width="100" header-align="center" align="center">
            <template slot-scope="scope">
              <!-- <span v-if="scope.row.registStatus == -1">退款中</span> -->
              <span v-if="scope.row.registStatus == 0" class="zwx-circle-text zwx-circle-underway">取消报名</span>
              <span v-if="scope.row.registStatus == 1" class="zwx-circle-text zwx-circle-complete">已报名</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="220" header-align="left" align="left">
            <template slot-scope="scope">
              <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="updateTrainingInfoDialog(scope.row)">学员修改</el-button>
              <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="scope.row.registWay == 1 && scope.row.studyState != 2 && scope.row.registStatus === 1" @click="refundOrderOrCancelRegisterByTraineeList(scope.row, 1)">退费</el-button>
              <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="scope.row.registWay == 2 && scope.row.studyState != 2 && scope.row.registStatus === 1" @click="refundOrderOrCancelRegisterByTraineeList(scope.row, 2)">取消报名</el-button>
              <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="scope.row.ifSignIn == 0 && scope.row.registStatus === 1" @click="supplementarySign(scope.row)">补签</el-button>
              <el-button v-if="scope.row.registStatus === 0" class="zwx-button zwx-button-text-26" type="danger" @click="deleteTdTraining(scope.row)">删除</el-button>
              <el-button v-if="scope.row.certificatePdfPath" class="zwx-button zwx-button-text-26" icon="el-icon-download" type="text" @click="openFilePreview('培训证明', scope.row.certificatePdfPath)">证书下载</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页器部分 -->
        <base-pagination :parentPage="studentManagementForm.currentPage" :pageSize="studentManagementForm.pageSize" :total="Number(studentManagementForm.total)" @currentChange="queryTrainingCourse" />
      </div>
    </div>
    <!-- 教材/考试 弹框 -->
    <base-dialog ref="coursewareDialogRef" class="courseware-dialog" title="课程明细" @cancel="coursewareDialogCancel" @close="coursewareDialogClose" :ifFooter="false">
      <!-- 教材列表展示部分 -->
      <el-table ref="teachingMaterialTable" class="zwx-table" row-key="rid" :data="trainingActivityListFormExp.teachingMaterialList" stripe tooltip-effect="light" max-height="310px">
        <el-table-column prop="coursewareTitle" label="课程名称" min-width="200" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="classHour" label="学时" header-align="center" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.coursewareType == 1 || scope.row.coursewareType == 3">-</span>
            <span v-else>{{ scope.row.classHour }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="requiredCourse" label="性质" header-align="center" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.coursewareType == 1 || scope.row.coursewareType == 3">-</span>
            <span v-else>{{ scope.row.requiredCourse ? '必修' : '选修' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 学时统计 -->
      <div class="classHour">
        已选课程合计：必修
        <span>{{ this.trainingActivityListFormExp.requiredCourseHours }}</span>
        学时，选修
        <span>{{ this.trainingActivityListFormExp.noRquiredCourseHours }}</span>
        学时
      </div>
    </base-dialog>

    <!-- 人员名单添加弹框 -->
    <base-dialog ref="themeActivityListAddDialogRef" class="theme-activity-list-add-dialog" @determine="determineAddDialog" :title="'添加学员'" @cancel="cancelAddDialog" @close="closeAddDialog">
      <el-form ref="themeActivityListAddDialogForm" class="zwx-form" :model="themeActivityListAddDialogForm" :rules="themeActivityListAddDialogFormRules" label-position="right" @submit.native.prevent>
        <div class="flex-row">
          <div class="left-info">
            <div class="tips flex-row">
              <div style="width: 16px; height: 16px; font-size: 12px; background: #ff8800; color: #fff; border-radius: 50%; text-align: center; margin-right: 8px">!</div>
              <div>操作提示</div>
            </div>
            <div class="title-text flex-row">
              <div style="width: 22px; height: 22px; font-size: 16px; background: #4a75f5; color: #fff; border-radius: 50%; text-align: center; margin-right: 8px">1</div>
              <div>通过证件号码检索学员档案</div>
            </div>
            <div class="info-box flex-column-center">
              <div>1、检索结果有记录，选中后自动填充学员信息</div>
              <div style="margin-top: 12px">2、检索结果无记录，手动输入学员信息</div>
            </div>
            <div class="title-text flex-row">
              <div style="width: 22px; height: 22px; font-size: 16px; background: #4a75f5; color: #fff; border-radius: 50%; text-align: center; margin-right: 8px">2</div>
              <div>通过所属单位检索单位信息</div>
            </div>
            <div class="info-box flex-column-center">
              <div>1、检索结果有记录，选中后自动填充单位信息</div>
              <div style="margin-top: 12px">2、检索结果无记录，手动输入单位信息</div>
            </div>
          </div>
          <div class="right-form" style="margin-top: 20px">
            <!-- 个人信息 -->
            <div class="zwx-circle-text zwx-circle-text:before zwx-circle-normal zwx-circle-normal:before" style="color: #505b89 !important; margin-bottom: 20px">个人信息</div>
            <div class="edit-form">
              <el-form-item label="证件号码：" label-width="110px" prop="cardNo">
                <search-input ref="searchInputRef" :key="keyDate" v-model="themeActivityListAddDialogForm.cardNo" :ifDelIcon="ifDelIcon" :ifSearchInputDisabled="ifSearchInputDisabled" @clickSearchIcon="clickSearchIcon" @clickDelIcon="clickDelIcon">
                  <div class="idc-item-box" v-for="(item, index) in trainingTraineeList" :key="index" @click="chooseItem(item)">
                    <div class="flex-row idc-name">
                      <div v-html="themeActivityListAddDialogForm.cardNo"></div>
                      <div style="margin-left: 12px">{{ item.userName }}</div>
                    </div>
                    <div class="flex-row">
                      <div class="unit-name">{{ item.fkByUnitId.unitName }}</div>
                      <!-- <div class="role-text">{{ item.roleTypeName || '--' }}</div> -->
                    </div>
                  </div>
                </search-input>
              </el-form-item>
            </div>
            <div class="edit-form">
              <el-form-item label="姓名：" label-width="110px" prop="userName">
                <el-input class="zwx-input" style="width: 160px !important" placeholder="请输入" v-model="themeActivityListAddDialogForm.userName" maxlength="50" clearable :disabled="formDisabled"></el-input>
              </el-form-item>
              <el-form-item label="证件类型：" label-width="82px" prop="cardType">
                <el-select class="zwx-select role-type-select" popper-class="zwx-select-popper" placeholder="请选择" v-model="themeActivityListAddDialogForm.cardType" :disabled="formDisabled">
                  <el-option v-for="item in idTypeList" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="edit-form">
              <el-form-item label="性别：" label-width="110px" prop="userSex">
                <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="themeActivityListAddDialogForm.userSex">
                  <el-radio v-for="item in sexList" :key="item.codeNo" class="zwx-radio" :label="item.codeNo">{{ item.codeName }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="手机号：" label-width="166px" prop="mobileTel">
                <el-input class="zwx-input" style="width: 200px !important" placeholder="请输入" v-model="themeActivityListAddDialogForm.mobileTel" maxlength="18" clearable :disabled="formDisabled"></el-input>
              </el-form-item>
            </div>
            <div class="edit-form">
              <el-form-item label="登录账号：" label-width="110px" prop="userNo">
                <el-input class="zwx-input" style="width: 160px !important" placeholder="请输入" v-model="themeActivityListAddDialogForm.userNo" maxlength="30" clearable :disabled="formDisabled"></el-input>
              </el-form-item>
              <el-form-item v-if="!ifDelIcon" label="登录密码：" label-width="82px" prop="initialPassword">
                <el-input class="zwx-input" style="width: 200px !important" placeholder="请输入" :type="newView ? 'password' : null" v-model="themeActivityListAddDialogForm.initialPassword" maxlength="50">
                  <i slot="suffix" :class="newView ? 'el-input__icon  outline chis-icon-outline-eye' : 'el-input__icon outline chis-icon-outline-eye-invisible'" @click="newView = !newView"></i>
                </el-input>
              </el-form-item>
              <el-form-item label="学员类别：" :label-width="ifDelIcon ? '81px' : '110px'" prop="traineeType">
                <el-select class="zwx-select role-type-select" popper-class="zwx-select-popper" placeholder="请选择" v-model="themeActivityListAddDialogForm.traineeType" clearable :disabled="false" @change="resetCourseInfo">
                  <el-option v-for="item in targetPopulationOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- <div class="mstype">
              <el-form-item label="学员类别：" label-width="110px" prop="traineeType">
                <el-select class="zwx-select role-type-select" placeholder="请选择" v-model="themeActivityListAddDialogForm.traineeType" clearable :disabled="false" @change="resetCourseInfo">
                  <el-option v-for="item in targetPopulationOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
                </el-select>
              </el-form-item>
            </div> -->
            <!-- 单位信息 -->
            <div class="zwx-circle-text zwx-circle-text:before zwx-circle-normal zwx-circle-normal:before" style="color: #505b89 !important; margin-bottom: 20px">单位信息</div>
            <div class="edit-form zwxlink">
              <el-form-item label="所属单位：" label-width="110px" prop="unitName">
                <unit-select width="460px" ref="unitSelect" v-model="themeActivityListAddDialogForm.unitName" requestUrl="/oh/repository/registerGetUnitInfoByKeyword-0" :ifShowConfirmDialog="false" :ifShowMoreBtn="false" :ifNeedMore="false" @unitNameSearchOrDel="unitNameDel" @change="unitNameSelect" @unitNameSearch="unitNameSearchTips" @loading="data => $emit('loading', data)" searchUnitName="unitName" />
                <!-- <organ-info-select ref="organInfoSelectRef" v-model="themeActivityListAddDialogForm.unitName" width="460px" :ifShowConfirmDialog="false" :options="themeActivityListAddDialogFormExp.searchUnitList" :searchVisible="themeActivityListAddDialogFormExp.unitNameSearchVisible" @unitNameSearchOrDel="unitNameSearchOrDel" @change="unitNameSelect" :disabled="false" /> -->
              </el-form-item>
              <div class="edit-form flex-row">
                <el-form-item label-width="110px" label="社会信用代码：" prop="creditCode">
                  <el-input class="zwx-input" style="width: 460px !important" v-model="themeActivityListAddDialogForm.creditCode" placeholder="请输入" clearable />
                </el-form-item>
              </div>
              <div class="msarea" style="display:flex;">
                <el-form-item label="行业分类：" label-width="110px" prop="industryName">
                  <div>
                    <el-input class="zwx-input zwx-occ-category-input" readonly="readonly" v-model.trim="themeActivityListAddDialogForm.industryName" style="width: 160px !important;" placeholder="" :read-only="true" @focus="msDeclareInfoCategoryDialog">
                      <template slot="append" v-if="!themeActivityListAddDialogForm.industryName">
                        <span><i class="el-icon-search" @click="msDeclareInfoCategoryDialog"></i></span>
                      </template>
                      <template slot="append" v-if="themeActivityListAddDialogForm.industryName">
                        <span><i style="cursor: pointer;" class="el-icon-delete" @click="clearIndustryName"></i></span>
                      </template>
                    </el-input>
                  </div>
                </el-form-item>
                <el-form-item label="所属地区：" label-width="87px" prop="busZoneCode">
                  <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChange" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" />
                </el-form-item>
              </div>
            </div>
            <!-- 课程信息 -->
            <div class="zwx-circle-text zwx-circle-text:before zwx-circle-normal zwx-circle-normal:before" style="color: #505b89 !important; margin-bottom: 20px">课程信息</div>
            <div class="edit-form">
              <el-form-item label="课程名称：" label-width="110px" prop="courseUid">
                <el-input class="zwx-input" style="width: 460px !important" v-model="themeActivityListAddDialogForm.courseName" placeholder="" maxlength="100" @focus="getMsDialog(themeActivityListAddDialogForm)" :suffix-icon="'el-icon-search'" />
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
      <div slot="footer" style="margin-left: 20px">
        <el-button class="zwx-button zwx-button-icontext-28" style="margin-right: 12px" icon="el-icon-plus" @click="determineAddDialog(1)">连续添加</el-button>
      </div>
    </base-dialog>
    <!-- 教材/考试 弹框 -->
    <base-dialog ref="coursewareoneDialogRef" class="coursewareone-dialog" @determine="coursewareoneDialogSubmit" title="添加" @cancel="coursewareoneDialogCancel" @close="coursewareoneDialogClose" :total="trainingKaoshiFormExp.materialTotal">
      <el-form ref="trainingKaoshiFormExp" class="zwx-form" :model="trainingKaoshiFormExp" style="height: 50px !important">
        <div class="edit-form">
          <el-form-item label="课程名称：" label-width="70px">
            <el-input class="zwx-input cc-input" style="cursor: pointer; min-width: 30px !important; width: 295px !important" placeholder="输入查询" v-model="trainingKaoshiFormExp.materialNameLike" maxlength="200" clearable></el-input>
          </el-form-item>
          <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="getTeachingMaterialList(1)">查询</el-button>
        </div>
      </el-form>
      <el-table ref="teachingMaterialTable" class="zwx-table" row-key="rid" :data="trainingKaoshiFormExp.teachingCourseList" border stripe tooltip-effect="light" max-height="310px">
        <el-table-column label="选择" width="80" header-align="center" align="center">
          <template slot-scope="scope">
            <el-radio class="zwx-radio-course" v-model="themeActivityListAddDialogForm.courseUid" :label="scope.row.uuid" style="margin-right: unset !important; width: 15px" @change="handleCoursewareSelectionChange(scope.row)"></el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="courseName" label="课程名称" min-width="550" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="onLineClassHoure" label="学时要求" header-align="center" align="center" width="100"></el-table-column> -->
        <el-table-column prop="onLineClassHoure" label="线上课程学时" header-align="center" align="center" width="120">
          <template slot-scope="{ row }">
            {{ row.onLineClassHoure || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="offLineClassHoure" label="线下课程学时" header-align="center" align="center" width="120">
          <template slot-scope="{ row }">
            {{ row.offLineClassHoure || '-' }}
          </template>
        </el-table-column>
      </el-table>
    </base-dialog>
    <!--导入弹出框-->
    <import-file ref="importFile" @success="exportSuccess"></import-file>
    <!-- <data-import class="staffinfo-import-dialog" ref="staffinfoImportDialogRef" title="批量导入学员2" :ifPass="false" accept=".xls,.xlsx,.XLS,.XLSX" :ifshow-upload="$zwxBase.verifyIsBlank(staffinfoImportDialog.annexName)" :size="5 * 1024 * 1024" sizeInfo="5M" :action="api + '/training/platform/importTrainingList-0'" :paramData="staffinfoImportDialog.paramData" :successCount="staffinfoImportDialog.successCount" :errorCount="staffinfoImportDialog.errorCount" :ifShowErrorMsg="$zwxBase.verifyIsNotBlank(staffinfoImportDialog.exportErrorUid)" :determineBtnDisabled="staffinfoImportDialog.determineBtnDisabled" :errorMsg="staffinfoImportDialog.errorMsg" @beforeUpload="fileBeforeUpload" @tempDownload="staffImportTempDownload" @deletionFile="deletionFile" @fileSubmitSuccess="fileSubmitSuccess" @fileSubmitError="fileSubmitError" @change="clickFileUpload" @determine="staffImportDialogDetermine" @errorReportDownload="errorReportDownload" @cancel="staffImportDialogCancel" @close="staffImportDialogClose"></data-import> -->
    <!-- 签到码 -->
    <el-dialog :title="selectQrCode == 1 ? '公众号报名码' : selectQrCode == 2 ? '签到码' : '小程序报名码'" :visible.sync="signInQrCodeVisible" class="bindQrCodeDialog" id="bindQrCodeDialogDiv">
      <img style="width: 260px; margin: 10px 40px 0px" :src="file + bindQrCodePath" />
      <div style="display: flex; justify-content: right; padding: 0 20px 10px;justify-content: center;">
        <el-button class="zwx-button zwx-button-28" @click="downloadSignInQrCode">下载</el-button>
      </div>
    </el-dialog>
    <!-- 学员修改 -->
    <base-dialog class="update-training-info-dialog" title="学员信息修改" ref="trainingInfoDialog" @determine="submitUpdateTrainingInfo" @close="cancelUpdateTrainingInfoDialog" @cancel="cancelUpdateTrainingInfoDialog">
      <el-form ref="updateTrainingInfoDialogForm" class="zwx-form edit-form" style="padding: 12px 16px !important;" :model="selectTrainingInfo" :rules="selectTrainingInfoRules" label-position="right" @close="cancelUpdateTrainingInfoDialog">
        <div>
          <el-form-item label="姓名：" label-width="82px" prop="userName">
            <el-input class="zwx-input" style="width: 200px !important" placeholder="请输入" v-model="selectTrainingInfo.userName" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="手机号：" label-width="82px" prop="mobileTel">
            <el-input class="zwx-input" style="width: 200px !important" placeholder="请输入" v-model="selectTrainingInfo.mobileTel" maxlength="18" clearable></el-input>
          </el-form-item>
          <el-form-item label="证件类型：" label-width="82px" prop="cardType">
            <el-select class="zwx-select role-type-select" popper-class="zwx-select-popper" placeholder="请选择" v-model="selectTrainingInfo.cardType" @change="checkIdc">
              <el-option v-for="item in idTypeList" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号：" label-width="82px" prop="cardNo">
            <el-input class="zwx-input" style="width: 200px !important" placeholder="请输入" v-model="selectTrainingInfo.cardNo" maxlength="18" clearable></el-input>
          </el-form-item>
          <el-form-item label="单位名称：" label-width="82px" prop="unitName">
            <el-input class="zwx-input" style="width: 502px !important" placeholder="请输入" v-model="selectTrainingInfo.unitName" maxlength="18" clearable></el-input>
          </el-form-item>
        </div>
      </el-form>
    </base-dialog>
    <!--行业分类弹窗-->
    <industry-select ref="msDeclareComponentCategory" :interfaceName="'/oh/repository'" @change="msDeclareComponentCategoryChange" />
  </div>
</template>
<script>
import Vue from 'vue'
import SearchInput from './components/searchInput'
import OrganInfoSelect from './components/organInfo-select'
import ImportFile from './components/import.vue'
export default {
  name: 'TrainingManagementDetail',
  components: { SearchInput, OrganInfoSelect,ImportFile },
  filters: {
    time: function(value) {
      if (!value) return ''
      return Vue.prototype.$system.formatDate(value, 'YYYY-MM-DD HH:mm')
    },
  },
  data() {
    return {
      tabTitles: ['班级信息', '学员管理'],
      activeName: this.$route.params.type || '1',
      opType: this.$route.params.type,
      api: this.$store.state.api,
      file: this.$store.state.file,
      userInfo: this.$store.state.users,
      users: this.$store.state.users,
      ifShowQd: this.$route.params.ifShowQd,
      ifShowBm: this.$route.params.ifShowBm,
      selectQrCode: 1,
      unitNature: null,
      trainingActivityListForm: {
        trainingActivityName: '',
        agencyUnitname: '',
        ifOnLineCourse: false,
        ifOffLineTraining: false,
        ifExam: false,
        ifCertificate: false,
        registStartTime: '',
        registEndTime: '',
        offLineStartTime: '',
        offLineTrainingAddr: '',
        courseStartTime: '',
        courseEndTime: '',
        leastNum: '',
        mostNum: '',
        examType: 1,
        examAddr: '',
        certificateType: 1,
        certificateUnit: '',
        certificateSeal: '',
        certificateList: [],
        courseExpList: [],
      },
      trainingKaoshiFormExp: {
        materialTotal: 0, // 课程分页总数
        materialNameLike: '', //课程名称查询
        teachingCourseList: [], //课程集合
        courseUid: '',
        courseName: '',
        pageSize: '',
        coursewareCurrentPage: '', // 弹框页码
      },
      trainingActivityListFormExp: {
        coursewareIndex: '',
        coursewareCurrentPage: '', // 弹框页码
        materialTotal: 0, // 教材分页总数
        requiredCourseHours: 0, // 必修总课时
        noRquiredCourseHours: 0, // 选修总课时
        pageSize: '',
        materialNameLike: '', //教材名称查询
        courseUid: '',
        courseName: '',
        onLineClassHoure: '', //课时
        trainingTypeOptions: [],
        teachingMaterialList: [], //教材集合
        teachingCourseList: [], //课程集合
      },
      registStatusMap: { '-1': '已截止', 0: '未开始', 1: '进行中' },
      studentManagementForm: {
        busZoneCode: '000000000000', //行政区划
        unitName: '', //所属单位
        userName: '', //学员名称
        traineeType: '', //学员类型
        trainingType: '', //培训类型
        registWay: '', //报名类型
        ifSignIn: '', //签到情况
        studyState: '', //线上课程进度：
        examResultInt: '', //考试情况：
        currentPage: 1,
        pageSize: 18,
        total: 0,
      },
      studenTableList: [],
      themeActivityListAddDialogForm: {
        activityId: '',
        unitName: '',
        creditCode: '',
        userName: '',
        mobileTel: '',
        cardType: '',
        cardNo: '',
        traineeType: '',
        busZoneCode: '',
        zoneType: '', //地区级别
        userNo: '',
        initialPassword: '',
        courseName: '',
        courseUid: '',
        unitRid: '',
        userUid: '',
      },
      themeActivityListAddDialogFormExp: {
        title: '',
        opType: '',
        searchUnitList: [],
        unitNameSearchVisible: false,
      },
      trainingCourseListFormExp: {
        courseUid: '',
        coursewareCurrentPage: '',
        materialTotal: '',
      },
      themeActivityListAddDialogFormRules: {
        busZoneCode: [
          { required: true, message: '请选择所属地区', trigger: ['blur', 'change'] },
          { validator: this.validateZoneType, trigger: ['blur', 'change'] },
        ],
        unitName: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
          { validator: this.validateUnitName, trigger: ['blur'] },
        ],
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobileTel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: this.validatePhoneNumber, trigger: ['blur'] },
        ],
        cardType: [{ required: true, message: '请选择证件类型', trigger: 'change' }],
        cardNo: [
          { required: true, message: '请输入证件号', trigger: 'blur' },
          { validator: this.validateCardNo, trigger: ['blur'] },
        ],
        traineeType: [{ required: true, message: '请选择学员类别', trigger: 'change' }],
        userNo: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        creditCode: [
          { required: true, message: '请输入社会信用代码', trigger: 'blur' },
          { validator: this.$validate.creditCode, trigger: ['change', 'blur'] },
        ],
        initialPassword: [
          { required: true, message: '请输入初始密码', trigger: ['change', 'blur'] },
          { validator: this.validatePassword, trigger: ['change', 'blur'] },
        ],
        courseUid: [{ required: true, message: '请选择课程', trigger: 'change' }],
        industryName: [{ required: true, message: '请选择行业分类', trigger: ['change', 'blur'] }],
        userSex: [{ required: true, message: '请选择性别', trigger: ['change', 'blur'] }],
      },
      selectTrainingInfoRules: {
        unitName: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
          // { validator: this.validateUnitName, trigger: ['blur'] },
        ],
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobileTel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: this.validatePhoneNumber, trigger: ['blur'] },
        ],
        cardType: [{ required: true, message: '请选择证件类型', trigger: ['change', 'blur'] }],
        cardNo: [
          { required: true, message: '请输入证件号', trigger: ['change', 'blur'] },
          { validator: this.validateCardNoUpdate, trigger: ['change', 'blur'] },
        ],
      },
      formDisabled: false,
      areaDisabled: true,
      tdTrainingTraineeExp: null,
      creditDisabled: true,
      ifDelIcon: false,
      ifSearchInputDisabled: false,
      ifSearchData: false,
      keyDate: new Date().getTime(),
      zoneCode12From: '',
      targetPopulationOptions: [],
      trainingTypeOptions: [],
      trainingTypeFilterOptions: [],
      newView: true,
      creditCodeDisabled: false,
      // 用户列表
      trainingTraineeList: [],
      // 单位列表
      trainningUnitList: [],
      idTypeList: [],
      // 人员导入弹框
      staffinfoImportDialog: {
        annexName: '',
        successCount: 0,
        errorCount: 0,
        exportErrorUid: '',
        determineBtnDisabled: false,
        errorMsg: '',
        paramData: {
          tokenUid: this.$store.state.users.uid,
          activityUid: this.$route.params.row.uuid,
        },
      },
      signInQrCodeVisible: false,
      updateTrainingInfoVisible: false,
      //待修改的学员信息
      selectTrainingInfo: {
        rid: '',
        userName: '',
        mobileTel: '',
        cardNo: '',
        cardType: '',
        unitName: '',
      },
      bindQrCodePath: '',
      bindQrCodeName: '',
      CourseCurrentPage: 1,
      ifShowOff: true,
      ifShowH5: true,
      ifShowApp: true,
      ifOnlineExam: true,
      sexList: [],
      agencyAreaList: [],
    }
  },
  created() {

    // this.zoneCode12From = this.users.fkByUnitId.fkByZoneId.zoneCode12;
    let config = window.top.vm.$store.state.config
    this.zoneCode12From = config.empower //'320200000000'
    this.studentManagementForm.busZoneCode = config.empower //'320200000000'
    this.ifShowOff = window.top.vm.$store.state.config.ifTrainingClassOffline
    this.ifShowH5 = window.top.vm.$store.state.config.ifHtmlApplication
    this.ifShowApp = window.top.vm.$store.state.config.appletApplicationUrl
    this.ifOnlineExam = window.top.vm.$store.state.config.ifOnlineExam
    this.ifTrainingExamSplit = window.top.vm.$store.state.config.ifTrainingExamSplit
  },
  mounted() {
    this.unitNature = window.top.vm.$store.state.config.unitNature
    this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('5002,1003,1001', data => {
      this.targetPopulationOptions = this.manyListFul(data['5002'], '')
      this.trainingTypeOptions = data['5002']
      this.idTypeList = data['1003']
      this.sexList = data['1001']
    })
    this.init()
    this.$nextTick(() => {
        this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
        this.$forceUpdate()
      })
    // this.getCertificateConfig()
  },
  methods: {
    reportDownload() {
      let data={
        activityId: this.$route.params.row.rid,
        ...this.studentManagementForm,
        activityUid: this.$route.params.row.uuid,
      }
      delete data.currentPage
      delete data.pageSize
      this.$FormatData.exportExcel(this.api + '/training/platform/exportTrainingActyvityTraineeListList-1', data)
    },
    exportSuccess(){
      this.queryTrainingCourse(1)
    },
    bizZoneChangeForm() {
      this.studentManagementForm.busZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    openFileDownlaod(fileName, filePath) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: [{
              fileName:fileName,
              filePath:filePath
            }],
            index: 0,
          },
        },
        '*'
      )
      // this.$system.downloadUrlFile(fileName, process.env.VUE_APP_FILE + filePath)
    },
    /**
     * 行业分类弹框
     */
    msDeclareInfoCategoryDialog() {
      this.$refs.msDeclareComponentCategory.show(true)
    },
    msDeclareComponentCategoryChange() {
      this.themeActivityListAddDialogForm.industryNo = this.$refs.msDeclareComponentCategory.msCategoryGet().codeNo
      this.themeActivityListAddDialogForm.industryLevelNo = this.$refs.msDeclareComponentCategory.msCategoryGet().levelCodeNo
      this.$set(this.themeActivityListAddDialogForm, 'industryName', this.$refs.msDeclareComponentCategory.msCategoryGet().industryName)
    },
    clearIndustryName() {
      this.themeActivityListAddDialogForm.industryNo = undefined
      this.themeActivityListAddDialogForm.industryLevelNo = undefined
      this.$set(this.themeActivityListAddDialogForm, 'industryName', null)
    },
    // 获取发证相关信息
    getCertificateConfig() {
      this.$system.get(
        this.api + '/training/platform/getCertificateConfig-0',
        {},
        false,
        false,
        data => {
          if (data.type == '00') {
            this.trainingActivityListForm.certificateUnit = data.data.certificateUnit
            this.trainingActivityListForm.certificateSeal = data.data.certificateSignPath
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    init() {
      this.$system.get(
        this.api + '/training/platform/getTrainingActivityMainInfo-1',
        { uuid: this.$route.params.row.uuid, editType: 2 },
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            try {
              data.activityMainExp.courseExpList = data.activityMainExp.activityCourseExpList || []
              if (this.opType == 1) {
                this.$emit('breadcrumb', '班级信息', true)
                this.trainingActivityListForm = JSON.parse(JSON.stringify(data.activityMainExp))
                if (this.trainingActivityListForm.courseExpList.length > 0) {
                  this.trainingActivityListForm.courseExpList.forEach((val, idx) => {
                    if (val.traineeType) {
                      this.trainingActivityListForm.courseExpList[idx].trainingTypeOptions = this.manyListFul(this.trainingActivityListFormExp.trainingTypeOptions, val.traineeType)
                    }
                  })
                }
              } else if (this.opType == 2) {
                this.$emit('breadcrumb', '学员管理', true)
                // this.$nextTick(() => {
                //   this.$refs.bizZoneArea1.initSearch(this.users.fkByUnitId.fkByZoneId.zoneCode12)
                //   this.$forceUpdate()
                // })
                this.trainingActivityListForm = JSON.parse(JSON.stringify(data.activityMainExp))
                if (this.trainingActivityListForm.courseExpList.length > 0) {
                  this.trainingActivityListForm.courseExpList.forEach((val, idx) => {
                    if (val.traineeType) {
                      this.trainingActivityListForm.courseExpList[idx].trainingTypeOptions = this.manyListFul(this.trainingActivityListFormExp.trainingTypeOptions, val.traineeType)
                    }
                  })
                }
                this.queryTrainingCourse(1)
              }
            } catch (error) {}
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    signInQrCodeShow(sceneType, type) {
      if (type == 1) {
        this.selectQrCode = 3
      } else {
        this.selectQrCode = sceneType
      }
      let data = {
        uuid: this.$route.params.row.uuid,
        sceneType,
      }
      this.$system.postJson(
        this.api + '/training/platform/trainingActivityMainUploadQrCode-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.signInQrCodeVisible = true
            let name = ''
            if (type == 1) {
              this.bindQrCodePath = data.appletQrCodePath
              name = '_小程序报名码'
            } else {
              this.bindQrCodePath = data.qrCodePath
              name = sceneType == 1 ? '_公众号报名码' : '_签到二维码'
            }
            this.bindQrCodeName = this.$route.params.row.trainingActivityName + name
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
      // this.signInQrCodeNow = row.signInQrCode
      // this.signInQrCodeVisible = true
    },
    downloadSignInQrCode() {
      if (this.$zwxBase.verifyIsNotBlank(this.bindQrCodePath)) {
        this.$system.downloadUrlFile(this.bindQrCodeName, this.$store.state.api + this.bindQrCodePath)
      }
    },
    resetFilterOptions(val) {
      if (val == '') {
        this.studentManagementForm.trainingType = ''
        this.trainingTypeFilterOptions = []
      } else {
        this.studentManagementForm.trainingType = ''
        this.trainingTypeFilterOptions = this.manyListFul(this.trainingTypeOptions, val)
      }
    },
    resetCourseInfo() {
      this.trainingKaoshiFormExp.courseName = ''
      this.trainingKaoshiFormExp.courseUid = ''
      this.themeActivityListAddDialogForm.courseName = ''
      this.themeActivityListAddDialogForm.courseUid = ''
    },
    coursewareoneDialogSubmit() {
      this.$set(this.themeActivityListAddDialogForm, 'courseName', this.trainingKaoshiFormExp.courseName)
      if (this.$zwxBase.verifyIsNotBlank(this.trainingKaoshiFormExp.courseUid)) {
        this.$set(this.themeActivityListAddDialogForm, 'courseUid', this.trainingKaoshiFormExp.courseUid)
      }
      this.trainingKaoshiFormExp.courseUid = ''
      this.$refs.coursewareoneDialogRef.show(false)
    },
    /**
     * 教材弹框取消
     */
    coursewareoneDialogCancel() {
      this.$refs.coursewareoneDialogRef.show(false)
    },
    coursewareoneDialogClose() {
      this.trainingKaoshiFormExp.coursewareIndex = ''
      this.trainingKaoshiFormExp.courseName = ''
      this.trainingKaoshiFormExp.courseUid = ''
      this.trainingKaoshiFormExp.materialNameLike = ''
      this.trainingKaoshiFormExp.teachingCourseList = []
      this.trainingKaoshiFormExp.coursewareCurrentPage = ''
      this.trainingKaoshiFormExp.materialTotal = 0
      this.trainingKaoshiFormExp.pageSize = 18
    },
    errorReportDownload() {
      this.$system.downloadUrlFile('学员管理错误数据.xls', this.api + '/training/platform/exportActivityListErrorExcel-0?exportErrorUid=' + this.staffinfoImportDialog.exportErrorUid)
    },
    /**
     * 员工信息导入弹框取消
     * */
    staffImportDialogCancel: function() {
      this.$refs.staffinfoImportDialogRef.show(false)
    },
    /**
     * 员工信息导入弹框关闭回调
     * */
    staffImportDialogClose() {
      this.staffinfoImportDialog.annexName = ''
      this.staffinfoImportDialog.uid = ''
      this.staffinfoImportDialog.successCount = 0
      this.staffinfoImportDialog.errorCount = 0
      this.staffinfoImportDialog.exportErrorUid = ''
      this.staffinfoImportDialog.paramData.unitId = ''
      this.staffinfoImportDialog.paramData.uuid = ''
      this.staffinfoImportDialog.determineBtnDisabled = false
      this.staffinfoImportDialog.errorMsg = ''
      this.$refs.staffinfoImportDialogRef.mainForm.passWord = ''
      this.queryTrainingCourse(1)
    },
    /**
     * 员工信息导入
     * */
    staffInfoImport() {
      this.$refs.importFile.show(true)
    },
    fileBeforeUpload(value) {
      if (value === false) {
        this.staffinfoImportDialog.determineBtnDisabled = true
      }
    },
    /**
     * 上传员工信息文件
     * */
    clickFileUpload(showList) {
      showList.forEach(item => {
        let flag = this.staffinfoImportDialog.annexName === item
        if (flag) {
          return
        }
        this.staffinfoImportDialog.annexName = item
      })
      if (this.$zwxBase.verifyIsNotBlank(this.staffinfoImportDialog.annexName)) {
        this.staffinfoImportDialog.errorMsg = ''
      }
    },
    /**
     * 员工信息导入弹框确认
     * */
    staffImportDialogDetermine: function() {
      if (this.$zwxBase.verifyIsBlank(this.staffinfoImportDialog.annexName)) {
        this.staffinfoImportDialog.errorMsg = '请上传文件'
        return
      } else {
        this.staffinfoImportDialog.errorMsg = ''
      }
      this.staffinfoImportDialog.determineBtnDisabled = true
      this.$emit('loading', true)
      // this.$system.postJson(
      //   this.api + '/training/platform/staffInfoImportUid-1',
      //   null,
      //   true,
      //   true,
      //   (data) => {
      //     if (data.type === '00') {
      //       this.staffinfoImportDialog.paramData.uuid = data.uid
      //       this.staffinfoImportDialog.paramData.passWord = this.$refs.staffinfoImportDialogRef.mainForm.passWord
      this.$refs.staffinfoImportDialogRef.fileSubmit()
      //     } else {
      //       this.$system.notify('错误', data.mess, 'error')
      //     }
      //   },
      //   (data) => {
      //     this.$emit('loading', false)
      //     this.$system.notify('错误', data.mess, 'error')
      //   }
      // )
    },
    fileSubmitSuccess(data) {
      this.$emit('loading', false)
      if (data.type === '00') {
        this.staffinfoImportDialog.successCount = data.successCount || 0
        this.staffinfoImportDialog.errorCount = data.errorCount || 0
        this.staffinfoImportDialog.exportErrorUid = data.exportErrorUid || ''
        if (this.$zwxBase.verifyIsBlank(this.staffinfoImportDialog.exportErrorUid)) {
          this.$refs.staffinfoImportDialogRef.show(false)
          this.$system.notify('成功', '导入成功' + this.staffinfoImportDialog.successCount + '条', 'success')
          this.queryTrainingCourse(1)
        }
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.staffinfoImportDialog.exportErrorUid = data.exportErrorUid || ''
      }
    },
    fileSubmitError(data) {
      this.$emit('loading', false)
      this.$system.notify('错误', data.mess, 'error')
      this.staffinfoImportDialog.exportErrorUid = data.exportErrorUid || ''
    },
    /**
     * 删除员工导入文件
     * */
    deletionFile() {
      this.staffinfoImportDialog.annexName = ''
      this.staffinfoImportDialog.exportErrorUid = ''
      this.staffinfoImportDialog.successCount = 0
      this.staffinfoImportDialog.errorCount = 0
      this.staffinfoImportDialog.determineBtnDisabled = false
    },
    /**
     * 员工导入模板下载
     * */
    staffImportTempDownload() {
      let data = {
        templateSort: 14,
      }
      this.$system.get(
        this.api + '/oh/repository/getFileTemplateByCode-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$emit('loading', true)
            this.$system.downloadUrlFile('培训学员导入模板', this.$store.state.file + data.filePath)
            this.$emit('loading', false)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
    /**
     * 打开教材弹框
     */
    getMsDialog(row) {
      this.trainingKaoshiFormExp.courseUid = row.activityCourseId + ''
      this.trainingKaoshiFormExp.courseName = row.activityCourseName
      this.getTeachingMaterialList()
      this.$refs.coursewareoneDialogRef.show(true)
    },
    /**
     * 教材列表查询
     */
    getTeachingMaterialList(currentPage) {
      // this.trainingKaoshiFormExp.coursewareCurrentPage = currentPage
      this.CourseCurrentPage = currentPage
      let data = {
        activityUid: this.$route.params.row.uuid,
        traineeType: this.themeActivityListAddDialogForm.traineeType,
        courseName: this.trainingKaoshiFormExp.materialNameLike,
        // currentPage:this.CourseCurrentPage
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getTrainingActivityCourseList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.trainingKaoshiFormExp.teachingCourseList = data.trainingActivityCourseList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    queryTrainingCourse(currentPage) {
      this.studentManagementForm.currentPage = currentPage
      let data = {
        activityId: this.$route.params.row.rid,
        ...this.studentManagementForm,
        activityUid: this.$route.params.row.uuid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getTrainingActyvityTraineeListList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            try {
              this.studenTableList = data.trainingActyvityTraineeListList
              this.studentManagementForm.pageSize = data.pageSize
              this.studentManagementForm.total = data.trainingActyvityTraineeListCount
            } catch (error) {}
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    bizZoneChange() {
      this.themeActivityListAddDialogForm.busZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
      this.themeActivityListAddDialogForm.zoneType = this.$refs.bizZoneArea.getChooseArea().zoneType
    },
    /**
     * 密码强度校验
     */
    validatePassword(rule, value, callback) {
      if (value.length < 8) {
        callback(new Error('密码应为8-16位，必须包含大写字母、小写字母和数字'))
      }
      let lev = 0
      //如果密码中存在数字，强度加一
      if (value.match(/\d+/g)) {
        lev++
      }
      //如果密码中存在小写字母，强度加一
      if (value.match(/[a-z]+/g) && value.match(/[A-Z]+/g)) {
        lev++
      }
      //如果密码中存在特殊字符，强度加一
      if (value.match(/[^a-zA-Z0-9]+/g)) {
        lev++
      }
      if (lev < 2) {
        callback(new Error('密码应为8-16位，必须包含大写字母、小写字母和数字'))
      }
      return callback()
    },
    /**
     * 单位名称校验
     */
    validateUnitName(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value) && value.length < 5) {
        callback(new Error('至少输入5个字符进行检索'))
      }
      callback()
    },
    /**
     * 手机号校验
     */
    validatePhoneNumber(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        this.$validate.mobilePhone(rule, value, callback)
      }
      return callback()
    },

    validateZoneType(rule, value, callback) {
      if (this.themeActivityListAddDialogForm.zoneType < 4) {
        callback(new Error('地区请选到街道级'))
      }
      callback()
    },

    /**
     * 当证件类型为身份证时校验证件号码
     */
    validateCardNo(rule, value, callback) {
      let cardType = this.themeActivityListAddDialogForm.cardType
      if (this.$zwxBase.verifyIsNotBlank(cardType) && cardType === '10030001' && this.$zwxBase.verifyIsNotBlank(value)) {
        this.$validate.idcCode(rule, value, callback)
      }
      return callback()
    },

    /**
     * 当证件类型为身份证时校验证件号码
     */
    validateCardNoUpdate(rule, value, callback) {
      let cardType = this.selectTrainingInfo.cardType
      if (this.$zwxBase.verifyIsNotBlank(cardType) && cardType === '10030001' && this.$zwxBase.verifyIsNotBlank(value)) {
        this.$validate.idcCode(rule, value, callback)
      }
      return callback()
    },
    areaIdLoadOver() {
      this.$nextTick(() => {
        if(this.$refs.themeActivityListAddDialogForm){
          this.$refs.themeActivityListAddDialogForm.clearValidate()
        }
        
      })
    },
    /**
     * 单位搜索和删除
     */
    unitNameDel() {
      // if (type === 'search') {
      //   // 查询企业信息
      //   let data = {
      //     unitName: this.themeActivityListAddDialogForm.unitName,
      //   }
      //   this.$emit('loading', true)
      //   this.$system.get(
      //     this.api + '/oh/repository/registerGetUnitInfoByKeyword-0',
      //     data,
      //     true,
      //     true,
      //     data => {
      //       this.$emit('loading', false)
      //       if (data.type == '00') {
      //         this.themeActivityListAddDialogFormExp.searchUnitList = data.trainningUnitList
      //         this.themeActivityListAddDialogFormExp.unitNameSearchVisible = true
      //         // this.themeActivityListAddDialogFormExp.unitNameSearchVisible = this.themeActivityListAddDialogFormExp.searchUnitList.length > 0
      //         // if (this.themeActivityListAddDialogFormExp.searchUnitList.length == 0) {
      //         //   this.$notify.info({ title: '提示', message: '查询无记录，请手动填写' })
      //         //   this.areaDisabled = false
      //         //   this.creditDisabled = false
      //         // }
      //       } else {
      //         this.$system.notify('错误', data.mess, 'error')
      //       }
      //     },
      //     () => {
      //       this.$emit('loading', false)
      //       this.$system.notify('错误', '网络连接失败', 'error')
      //     }
      //   )
      // }
      // if (type === 'del') {
      // 删除页面企业信息
      this.themeActivityListAddDialogFormExp.searchUnitList = []
      this.themeActivityListAddDialogForm.unitName = ''
      this.themeActivityListAddDialogForm.creditCode = ''
      this.themeActivityListAddDialogForm.unitRid = ''
      this.themeActivityListAddDialogForm.busZoneCode = this.zoneCode12From
      // this.formDisabled = false
      this.areaDisabled = true
      this.creditDisabled = true
      this.$nextTick(() => {
        this.$refs.bizZoneArea.initSearch(this.themeActivityListAddDialogForm.busZoneCode)
        this.$refs.themeActivityListAddDialogForm.clearValidate()
      })
      // }
    },
    // 未查出数据提示
    unitNameSearchTips() {
      this.$system.notify('提示', '未查出单位信息，请手动填写', 'info')
    },
    /**
     * 单位选择
     */
    unitNameSelect(item) {
      if (this.$zwxBase.verifyIsNotBlank(item.rid)) {
        this.themeActivityListAddDialogForm.unitName = item.unitName
        this.themeActivityListAddDialogForm.creditCode = item.creditCode
        this.themeActivityListAddDialogForm.busZoneCode = item.busZoneCode
        this.themeActivityListAddDialogForm.unitRid = item.rid
        this.$nextTick(() => {
          this.$refs.bizZoneArea.initSearch(this.themeActivityListAddDialogForm.busZoneCode)
          this.$refs.themeActivityListAddDialogForm.clearValidate()
        })
        this.themeActivityListAddDialogFormExp.unitNameSearchVisible = false
      } else {
        //客户信息
        this.themeActivityListAddDialogForm.unitName = item.unitName
        this.themeActivityListAddDialogForm.creditCode = item.creditCode
        this.themeActivityListAddDialogFormExp.unitNameSearchVisible = false
      }
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    // 点击搜索图标
    clickSearchIcon(searchValue) {
      this.getUserList(searchValue)
    },
    // 点击查询到的单个item
    chooseItem(item) {
      this.themeActivityListAddDialogForm.unitName = item.fkByUnitId.unitName
      this.themeActivityListAddDialogForm.creditCode = item.fkByUnitId.socialCode
      this.themeActivityListAddDialogForm.userName = item.userName
      this.themeActivityListAddDialogForm.mobileTel = item.mobileTel
      this.themeActivityListAddDialogForm.cardType = item.cardType
      // this.themeActivityListAddDialogForm.cardNo = this.$zwxSm.sm4JsDecrypt(item.cardNoEncrypt)
      this.themeActivityListAddDialogForm.initialPassword = this.$zwxSm.sm4JsDecrypt(item.password)
      // this.themeActivityListAddDialogForm.traineeType = item.roleCode
      this.themeActivityListAddDialogForm.userUid = item.uid
      this.themeActivityListAddDialogForm.userSex = item.userSex
      // this.$refs.organInfoSelectRef.creditCode = item.fkByUnitId.socialCode
      // this.themeActivityListAddDialogForm.busZoneCode = item.tdTrainingUnitExp.busZoneCode || '000000000000'
      this.themeActivityListAddDialogForm.userNo = item.userNo
      this.$nextTick(() => {
        // this.$refs.bizZoneArea.initSearch(this.themeActivityListAddDialogForm.busZoneCode)
        this.$refs.themeActivityListAddDialogForm.clearValidate()
      })
      if (item.userInfoSubExp) {
        this.themeActivityListAddDialogForm.industryNo = item.userInfoSubExp.industryNo
        this.themeActivityListAddDialogForm.industryLevelNo = item.userInfoSubExp.industryLevelNo
        this.$set(this.themeActivityListAddDialogForm, 'industryName', item.userInfoSubExp.industryName)
      }
      this.formDisabled = true
      this.areaDisabled = true
      this.ifDelIcon = true
      this.ifSearchInputDisabled = true
      this.ifSearchData = false
      // if (item.tdTrainingUnitExp.rid) {
      //   this.themeActivityListAddDialogForm.unitRid = item.tdTrainingUnitExp.rid
      // }
      if (this.themeActivityListAddDialogForm.unitName) {
        this.$refs.organInfoSelectRef.unitNameDisabled = true
        this.$forceUpdate()
      }
      if (this.themeActivityListAddDialogForm.creditCode) {
        this.creditDisabled = true
      }
      this.tdTrainingTraineeExp = {
        rid: item.rid,
        userUuid: item.userUuid,
      }
    },
    // 点击删除图标
    clickDelIcon() {
      this.themeActivityListAddDialogForm.unitName = ''
      this.themeActivityListAddDialogForm.unitName = ''
      this.themeActivityListAddDialogForm.creditCode = ''
      this.themeActivityListAddDialogForm.userName = ''
      this.themeActivityListAddDialogForm.mobileTel = ''
      this.themeActivityListAddDialogForm.cardType = ''
      this.themeActivityListAddDialogForm.cardNo = ''
      this.themeActivityListAddDialogForm.traineeType = ''
      this.themeActivityListAddDialogForm.busZoneCode = '000000000000'
      this.themeActivityListAddDialogForm.userNo = ''
      this.themeActivityListAddDialogForm.userUid = ''
      this.themeActivityListAddDialogForm.unitRid = ''
      // this.themeActivityListAddDialogForm.userSex = undefined
      this.$set(this.themeActivityListAddDialogForm, 'userSex', undefined)
      this.clearIndustryName()
      this.$nextTick(() => {
        this.$refs.bizZoneArea.initSearch(this.themeActivityListAddDialogForm.busZoneCode)
        this.$refs.themeActivityListAddDialogForm.clearValidate()
      })
      this.formDisabled = false
      this.areaDisabled = true
      // this.$refs.organInfoSelectRef.unitNameDisabled = true
      this.creditDisabled = true
      this.ifDelIcon = false
      this.ifSearchInputDisabled = false
      this.ifSearchData = false
      this.tdTrainingTraineeExp = null
      this.$refs.organInfoSelectRef.searchOrDelIcon = 'el-icon-search'
      this.$refs.organInfoSelectRef.unitNameDisabled = false
    },
    //获取用户列表
    getUserList(searchValue) {
      this.$emit('loading', true)
      let data = {
        idc: this.$zwxSm.sm4JsEncrypt(searchValue),
      }
      this.$system.get(
        this.api + '/systematic/getUserInfoByIdc-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          try {
            if (data.type === '00') {
              if (data.ifExist) {
                data.tsUserInfoExp.idc = this.$zwxSm.sm4JsDecrypt(data.tsUserInfoExp.idc)
                data.tsUserInfoExp.mobileTel = this.$zwxSm.sm4JsDecrypt(data.tsUserInfoExp.mobileTel)
                this.trainingTraineeList = [data.tsUserInfoExp]
                // this.changeColor(this.trainingTraineeList, searchValue) //调用搜索词方法
                this.$refs.searchInputRef.visible = true
              } else {
                this.trainingTraineeList = []
                this.$notify.info({ title: '提示', message: '查询无记录，请手动填写' })
                this.formDisabled = false
                this.$refs.unitSelect.unitNameDisabled = false
                // this.$refs.organInfoSelectRef.unitNameDisabled = false
                this.ifSearchInputDisabled = false
                this.ifSearchData = true
              }
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$notify.error({ title: '错误', message: data.mess })
            }
          } catch (error) {}
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
    // 搜索关键词高亮方法
    changeColor(result, searchValue) {
      //result为接口返回的数据
      result.map((item, index) => {
        if (searchValue) {
          /**
           * 使用正则表达式进行全文匹配关键词
           * ig : 表示 全文查找 ,忽略大小写
           *  i : 忽略大小写
           *  g : 全文查找
           *
           * 使用字符串的replace方法进行替换
           * stringObject.replace('被替换的值',替换的值)
              cardNo是你要高亮的字段
           */
          let replaceReg = new RegExp(searchValue, 'ig')
          let replaceString = `<span style="color: #ed4014">${searchValue}</span>`
          result[index].cardNo = item.cardNo.replace(replaceReg, replaceString)
        }
      })
      this.records = result
    },
    /**
     * 添加主题活动名单
     */
    addOrEditThemeActivityList() {
      this.themeActivityListAddDialogForm.activityId = this.rid
      this.$refs.themeActivityListAddDialogRef.show(true)
      this.closeAddDialog()
      this.clearIndustryName()
      this.$nextTick(() => {
        this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
        this.$refs.themeActivityListAddDialogForm.clearValidate()
        // this.$refs.organInfoSelectRef.unitNameDisabled = true
        this.$forceUpdate()
      })
    },
    /**
     * 添加名单弹框确认
     */
    determineAddDialog(num) {
      //单位地区必须选到街道
      let busZoneCode = this.themeActivityListAddDialogForm.busZoneCode //地区编码
      if (busZoneCode == 1) {
        this.$notify.error({ title: '错误', message: '地区必须选到街道' })
        return
      }
      let data = {
        // activityCourseId: this.themeActivityListAddDialogForm.courseUid, //培训班课程id
        // tdTrainingTraineeExp: {
        courseName: this.themeActivityListAddDialogForm.courseName,
        courseUid: this.themeActivityListAddDialogForm.courseUid,
        activityUid: this.$route.params.row.uuid,
        userName: this.themeActivityListAddDialogForm.userName, //姓名
        cardType: this.themeActivityListAddDialogForm.cardType, //+证件类型
        idc: this.$zwxSm.sm4JsEncrypt(this.themeActivityListAddDialogForm.cardNo), //证件号
        password: this.ifDelIcon ? '' : this.$zwxSm.sm4JsEncrypt(this.themeActivityListAddDialogForm.initialPassword), //密码
        userNo: this.themeActivityListAddDialogForm.userNo, //账号
        phoneNumber: this.$zwxSm.sm4JsEncrypt(this.themeActivityListAddDialogForm.mobileTel), //+手机号码
        traineeType: this.themeActivityListAddDialogForm.traineeType,
        userSex: this.themeActivityListAddDialogForm.userSex,
        // rid: this.themeActivityListAddDialogForm.userUid,
        // }, //培训用户信息
        // tdTrainingUnitExp: {
        // rid: this.themeActivityListAddDialogForm.unitRid,
        userUid: this.themeActivityListAddDialogForm.userUid,
        unitName: this.themeActivityListAddDialogForm.unitName,
        userInfoSubExp: {
          industryNo: this.themeActivityListAddDialogForm.industryNo,
          industryLevelNo: this.themeActivityListAddDialogForm.industryLevelNo,
          industryName: this.themeActivityListAddDialogForm.industryName,
        },
        creditCode: this.themeActivityListAddDialogForm.creditCode, //社会代码
        bizZone: this.themeActivityListAddDialogForm.busZoneCode, //地区编码
        // }, //单位信息
      }
      this.$refs.themeActivityListAddDialogForm.validate(valid => {
        if (valid) {
          this.$emit('loading', true)
          this.$system.postJson(
            this.api + '/training/platform/addOrModifyTrainingActyvityTraineeList-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type === '00') {
                this.$notify.success({ title: '成功', message: data.mess })
                if (num == 1) {
                  this.themeActivityListAddDialogForm.rid = ''
                  this.themeActivityListAddDialogForm.unitName = ''
                  this.themeActivityListAddDialogForm.creditCode = ''
                  this.themeActivityListAddDialogForm.mobileTel = ''
                  this.themeActivityListAddDialogForm.cardType = ''
                  this.themeActivityListAddDialogForm.cardNo = ''
                  this.themeActivityListAddDialogForm.traineeType = ''
                  this.themeActivityListAddDialogForm.userName = ''
                  this.themeActivityListAddDialogForm.userNo = ''
                  this.themeActivityListAddDialogForm.initialPassword = ''
                  this.themeActivityListAddDialogForm.courseName = ''
                  this.themeActivityListAddDialogForm.courseUid = ''
                  this.themeActivityListAddDialogForm.unitRid = ''
                  this.themeActivityListAddDialogForm.userUid = ''
                  this.themeActivityListAddDialogFormExp.opType = ''
                  this.themeActivityListAddDialogFormExp.searchUnitList = []
                  this.themeActivityListAddDialogFormExp.unitNameSearchVisible = false
                  this.themeActivityListAddDialogForm.busZoneCode = '000000000000'
                  this.formDisabled = true
                  this.areaDisabled = true
                  this.creditDisabled = true
                  this.ifDelIcon = false
                  this.ifSearchInputDisabled = false
                  this.ifSearchData = false
                  this.$nextTick(() => {
                    this.$refs.bizZoneArea.initSearch(this.themeActivityListAddDialogForm.busZoneCode)
                    this.$refs.unitSelect.unitNameDisabled = false
                    this.$refs.themeActivityListAddDialogForm.clearValidate()
                  })
                  this.clearIndustryName()
                  this.$forceUpdate()
                  this.keyDate = new Date().getTime()
                } else {
                  this.$refs.themeActivityListAddDialogRef.show(false)
                }
                this.queryTrainingCourse(1)
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$notify.error({ title: '错误', message: data.mess })
              }
            },
            () => {
              this.$emit('loading', false)
              this.$notify.error({ title: '错误', message: '网络连接失败' })
            }
          )
        }
      })
    },
    /**
     * 取消添加名单弹框
     */
    cancelAddDialog() {
      this.$refs.themeActivityListAddDialogRef.show(false)
    },
    /**
     * 关闭添加名单弹框回调
     */
    closeAddDialog() {
      this.themeActivityListAddDialogForm.userUid = ''
      this.themeActivityListAddDialogForm.unitName = ''
      this.themeActivityListAddDialogForm.creditCode = ''
      this.themeActivityListAddDialogForm.mobileTel = ''
      this.themeActivityListAddDialogForm.cardType = ''
      this.themeActivityListAddDialogForm.cardNo = ''
      this.themeActivityListAddDialogForm.traineeType = ''
      this.themeActivityListAddDialogForm.busZoneCode = ''
      this.themeActivityListAddDialogForm.userName = ''
      this.themeActivityListAddDialogForm.userNo = ''
      this.themeActivityListAddDialogForm.initialPassword = ''
      this.themeActivityListAddDialogForm.courseUid = ''
      this.themeActivityListAddDialogForm.courseName = ''
      this.themeActivityListAddDialogFormExp.opType = ''
      this.themeActivityListAddDialogFormExp.searchUnitList = []
      this.themeActivityListAddDialogFormExp.unitNameSearchVisible = false
      this.$set(this.themeActivityListAddDialogForm, 'userSex', undefined)
      this.formDisabled = true
      this.areaDisabled = true
      // this.$refs.organInfoSelectRef.unitNameDisabled = true
      this.creditDisabled = true
      this.ifDelIcon = false
      this.ifSearchInputDisabled = false
      this.ifSearchData = false
      this.tdTrainingTraineeExp = null
      this.$forceUpdate()
    },
    traineeTypeChange(data, arr) {
      arr.trainingType = ''
      arr.trainingTypeOptions = this.manyListFul(this.trainingActivityListFormExp.trainingTypeOptions, data)
    },
    addCourseExpList() {
      this.trainingActivityListForm.courseExpList.push({
        traineeType: '',
        trainingType: '0101',
        courseUid: '',
        onLineClassHoure: '',
        offLineClassHoure: '',
        registType: '',
        expiryDate: '',
      })
    },
    deleteList(index) {
      this.$system.msgbox('', '提示', '是否确认删除？', '确定', '取消', () => {
        this.trainingActivityListForm.courseExpList.splice(index, 1)
        this.$system.notify('成功', '删除成功', 'success')
      })
    },
    submit() {
      this.$refs.trainingActivityListFormRef.validate(valid => {
        if (valid) {
          this.$emit('loading', true)
          this.$system.postJson(
            this.api + '/training/platform/addOrModifyTdTrainingActivityMain-1',
            this.trainingActivityListForm,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type === '00') {
                this.$notify.success({ title: '成功', message: data.mess })
                this.$router.replace({ name: 'TrainingManagement', params: { fresh: true } })
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$notify.error({ title: '错误', message: data.mess })
              }
            },
            () => {
              this.$emit('loading', false)
              this.$notify.error({ title: '错误', message: '网络连接失败' })
            }
          )
        }
      })
    },
    back() {
      this.$router.replace({ name: 'TrainingManagement' })
    },
    /**
     * 教材弹框取消
     */
    coursewareDialogCancel() {
      this.$refs.coursewareDialogRef.show(false)
      this.trainingActivityListFormExp.teachingMaterialList = []
      this.trainingActivityListFormExp.requiredCourseHours = 0
      this.trainingActivityListFormExp.noRquiredCourseHours = 0
    },
    /**
     * 教材弹框关闭
     */
    coursewareDialogClose() {
      this.trainingActivityListFormExp.teachingMaterialList = []
      this.trainingActivityListFormExp.requiredCourseHours = 0
      this.trainingActivityListFormExp.noRquiredCourseHours = 0
    },
    /**
     * 课件类别选择弹框部分
     */
    //表格选择事件
    handleCoursewareSelectionChange(selection) {
      this.$set(this.trainingKaoshiFormExp, 'courseName', selection.courseName)
      this.$set(this.trainingKaoshiFormExp, 'courseUid', selection.uuid)
    },
    showCourseInfo(row) {
      this.trainingCourseListFormExp.courseUid = row.courseUid + ''
      this.getCourseInfoList(1)
    },
    //教材弹框分页
    coursewareDialogCurrent(currentPage) {
      this.getCourseInfoList(currentPage)
    },
    getCourseInfoList(currentPage) {
      this.trainingCourseListFormExp.coursewareCurrentPage = currentPage
      let data = {
        // currentPage: this.trainingCourseListFormExp.coursewareCurrentPage,
        editType: 2,
        uuid: this.trainingCourseListFormExp.courseUid,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getTrainingCourseDetail-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.trainingActivityListFormExp.teachingMaterialList = data.trainingCourse.tbCoursewareInfoExpList
            // this.trainingCourseListFormExp.materialTotal = data.tbTrainingCourseExp.pageTotalCount
            const requiredCourseList = this.trainingActivityListFormExp.teachingMaterialList.filter(item => {
              return item.coursewareType == 2 && item.requiredCourse
            })
            const noRequiredCourseList = this.trainingActivityListFormExp.teachingMaterialList.filter(item => {
              return item.coursewareType == 2 && !item.requiredCourse
            })
            if (requiredCourseList.length > 0) {
              let totalScore = requiredCourseList.reduce((pre, cur) => {
                return pre + Number(cur.classHour)
              }, 0)
              this.trainingActivityListFormExp.requiredCourseHours = totalScore
            } else {
              this.trainingActivityListFormExp.requiredCourseHours = 0
            }
            if (noRequiredCourseList.length > 0) {
              let totalScore1 = noRequiredCourseList.reduce((pre, cur) => {
                return pre + Number(cur.classHour)
              }, 0)
              this.trainingActivityListFormExp.noRquiredCourseHours = totalScore1
            } else {
              this.trainingActivityListFormExp.noRquiredCourseHours = 0
            }
            this.$refs.coursewareDialogRef.show(true)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    activeChange(val) {
      if (val == 1) {
        this.$emit('breadcrumb', '班级信息', true)
        // 获取详情
      } else if (val == 2) {
        this.$emit('breadcrumb', '学员管理', true)
        this.$nextTick(() => {
          // this.$refs.bizZoneArea1.initSearch('320200000000')
          this.$forceUpdate()
        })
        // this.studentManagementForm.busZoneCode = '320200000000'
        this.queryTrainingCourse(1)
        // 查询名单列表
      }
    },
    //退款或直接取消报名
    refundOrderOrCancelRegisterByTraineeList(row, type) {
      this.$system.msgbox('', '提示', type == 1 ? '是否确认退费？' : '是否确认取消报名？', '确定', '取消', () => {
        //查询是否开过发票
        const param = { uuid: row.uuid, orderNo: row.orderNo }
        let url = '/training/platform/cancelTrainingActyvityTraineeList-1'
        if (type == 1) {
          url = '/payment/wechatRefundsApply-1'
        }
        this.$system.postJson(
          this.api + url,
          param,
          true,
          true,
          data => {
            if (data.type === '00') {
              // this.$system.msgbox('', '提示', '该学员已申请开票，请确认是否继续退费？', '确定', '取消', () => {
              //   this.refundOrder(row, type)
              // })
              if (data.status) {
                if (data.status === '03') {
                  this.$system.notify('成功', '退款进行中，请稍后刷新查看', 'success')
                } else {
                  this.$system.notify('成功', data.mess, 'success')
                }
              }
              this.queryTrainingCourse(this.studentManagementForm.currentPage)
            } else {
              // this.refundOrder(row, type)
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          data => {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        )
      })
    },

    refundOrder(row) {
      const data = { traineeListIdStr: this.$zwxSm.sm4JsEncrypt(row.rid) }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/refundOrderOrCancelRegisterByTraineeList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.queryTrainingCourse(this.studentManagementForm.currentPage)
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },

    // 学员补签
    supplementarySign(row) {
      const data = { uuid: row.uuid }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/training/platform/supplementarySignByActyvityTraineeList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', '补签成功', 'success')
            this.queryTrainingCourse(this.studentManagementForm.currentPage)
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },

    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    updateTrainingInfoDialog(row) {
      this.$refs.trainingInfoDialog.show(true)
      this.selectTrainingInfo.uuid = row.uuid
      this.selectTrainingInfo.userName = row.userName
      this.selectTrainingInfo.unitName = row.unitName
      this.selectTrainingInfo.mobileTel = this.$zwxSm.sm4JsDecrypt(row.phoneNumber)
      this.selectTrainingInfo.cardNo = this.$zwxSm.sm4JsDecrypt(row.idc2)
      this.selectTrainingInfo.cardType = row.cardType
    },
    cancelUpdateTrainingInfoDialog() {
      //清空绑定的校验结果
      this.$refs.updateTrainingInfoDialogForm.resetFields()
      this.$refs.trainingInfoDialog.show(false)
      this.selectTrainingInfo = {
        rid: '',
        userName: '',
        mobileTel: '',
        cardNo: '',
        cardType: '',
        unitName: '',
      }
    },
    checkIdc(value) {
      this.$refs.updateTrainingInfoDialogForm.validate('cardNo')
    },
    //学员修改
    submitUpdateTrainingInfo() {
      let param = {
        uuid: this.selectTrainingInfo.uuid,
        unitName: this.selectTrainingInfo.unitName,
        userInfoExp: {
          userName: this.selectTrainingInfo.userName,
          mobileTel: this.$zwxSm.sm4JsEncrypt(this.selectTrainingInfo.mobileTel),
          idc: this.$zwxSm.sm4JsEncrypt(this.selectTrainingInfo.cardNo),
          cardType: this.selectTrainingInfo.cardType,
        },
      }
      this.$system.postJson(
        this.api + '/training/platform/modifyUnitAndUserInfo-1',
        param,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', '修改成功', 'success')
            this.cancelUpdateTrainingInfoDialog()
            this.queryTrainingCourse(this.studentManagementForm.currentPage)
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },

    // 学员删除
    deleteTdTraining(row) {
      const data = { rid: row.rid }
      this.$system.msgbox('', '提示', '是否确认删除？', '确定', '取消', () => {
        this.$system.postJson(
          this.api + '/training/platform/deleteTdTrainingActyvityTraineeList-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', '删除成功', 'success')
              this.queryTrainingCourse(this.studentManagementForm.currentPage)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          data => {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        )
      })
    },
  },
}
</script>
<style lang="less" scoped>
.ms-training-management-detail {
  .flex-row {
    display: flex;
    align-items: center;
  }
  .flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .theme-activity-line {
    width: 1px;
    height: 66px;
    background: #e1e5f4;
    margin: 0 20px;
  }

  .theme-activity-basicinfo {
    padding: 20px;
    display: flex;

    .theme-activity-basicinfo-label {
      max-width: 365px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #777f89;
      line-height: 22px;
    }

    .course-pic-info {
      width: 200px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
      }
    }

    .theme-activity-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .theme-activity-title {
        height: 18px;
        font-size: 18px;
        font-family: 'Harmony Medium';
        text-align: left;
        color: #191919;
        line-height: 18px;
        margin-bottom: 20px;
      }

      .theme-activity-detail {
        display: flex;

        .theme-activity-intro {
          display: flex;
        }

        .theme-activity-intro-detail {
          max-width: 648px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          word-break: break-all;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #777f89;
          line-height: 22px;
        }
      }
    }
  }
  .msarea {
    .cascader-area-biz-zone {
      /deep/ .zwx-input {
        width: 192px !important;
      }
    }
  }

  .theme-activity-label {
    min-width: 70px;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #3a485e;
    line-height: 14px;
  }

  .theme-activity-value {
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #191919;
    line-height: 14px;
  }

  .theme-activity-row {
    display: flex;
    align-items: center;
  }

  .theme-activity-form {
    padding: 20px 20px 0 20px;

    .theme-activity-form-line {
      width: 2px;
      height: 10px;
      background: #e1e5f4;
      margin: 0 12px;
    }

    .theme-activity-form-divider {
      height: 1px;
      border: 1px dashed #d5d9e0;
      margin: 20px 0;
    }

    .subtitle {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: 'Harmony Medium';
      text-align: left;
      color: #505b89;
      line-height: 14px;
      margin-bottom: 20px;
    }

    .subtitle-flag {
      width: 6px;
      height: 6px;
      background: #316cf5;
      border-radius: 50%;
      margin-right: 6px;
    }

    .theme-activity-course-exam {
      padding-bottom: 20px;

      .theme-activity-course {
        margin-right: 200px;
      }
    }

    .lecturer-card-box {
      display: flex;
      flex-wrap: wrap;

      .lecturer-card {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 320px;
        height: 90px;
        background: #f5f7fb;
        border: 1px solid #a8bbcf;
        border-radius: 4px;
        margin-bottom: 16px;
        margin-right: 20px;
        padding-right: 16px;

        .lecturer-detail {
          display: flex;
          align-items: center;

          .lecturer-avatar {
            display: flex;
            align-items: center;
            width: 56px;
            height: 56px;
            border-radius: 50%;
            margin-left: 16px;
            margin-right: 20px;

            img {
              width: 56px;
              height: 56px;
              border-radius: 50%;
            }
          }
          .lecturer-detail-column {
            display: flex;
            flex-direction: column;
          }

          .lecturer-detail-row {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .registration-way {
    padding: 20px;
  }

  .registration-way .theme-activity-row {
    margin-bottom: 16px;

    .registration-poster {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      cursor: pointer;

      img {
        max-height: 100%;
      }
    }
  }
  .mstype {
    /deep/ .role-type-select .el-input__inner {
      width: 160px !important;
    }

    /deep/ .cascader-area-biz-zone .zwx-input {
      width: 160px !important;
    }
  }
  /deep/ .role-type-select .el-input__inner {
    width: 200px !important;
  }

  /deep/ .cascader-area-biz-zone .zwx-input {
    width: 200px !important;
  }

  /deep/ .theme-activity-list-add-dialog {
    .zwx-form {
      padding: 0 !important;
    }
    .el-dialog {
      width: 910px !important;
      min-width: 910px !important;
      .left-info {
        width: 292px;
        height: 569px;
        border-right: 1px solid #ecf0f5;
        padding: 17px 0 27px 20px;
        margin-right: 20px;

        .tips {
          width: 252px;
          height: 30px;
          background: #fff4eb;
          border: 1px solid #f0cdb9;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 400;
          color: #dc7a0a;
          padding-left: 12px;
        }
        .title-text {
          font-size: 14px;
          font-family: 'Harmony Medium';
          font-weight: 500;
          color: #3a485e;
          margin: 20px 0 12px 8px;
        }
        .info-box {
          width: 252px;
          height: 112px;
          background: #f5f7fb;
          font-size: 14px;
          font-weight: 400;
          color: #777f89;
          padding: 0 14px;
        }
      }
      .right-form {
        flex: 1;
      }
    }
  }
  .classHour {
    height: 38px;
    line-height: 38px;
    padding-left: 14px;
    span {
      color: red;
    }
  }
  .detail-title {
    font-size: 20px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Medium;
    font-weight: 500;
    text-align: left;
    color: #191919;
    padding: 20px 0 16px;
    margin: 0 24px;
    border-bottom: 1px dashed #d5d9e0;
  }
  .base-info {
    padding: 16px 0;
    &-wrapper {
      display: flex;
    }
    .detail-row {
      display: flex;
      .detail-item {
        display: flex;
        width: 450px;
        min-height: 30px;
        align-items: flex-start;
        padding-left: 24px;
        .title {
          font-size: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
          font-weight: 400;
          color: #3a485e;
          .title-icon {
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #ffaf92;
            border-radius: 50%;
            margin-right: 6px;
          }
        }
        .detail-info-txt {
          font-size: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #191919;
        }
      }
    }
  }
  .training-info {
    padding: 20px 20px;
    .info-row {
      margin-bottom: 24px;
      .title {
        display: flex;
        margin-bottom: 16px;
        align-items: center;
        .title-icon {
          width: 6px;
          height: 6px;
          background: #316cf5;
          border-radius: 50%;
          margin-right: 6px;
        }
        .title-txt {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: left;
          color: #505b89;
          height: 14px;
          line-height: 14px;
        }
      }
      .detail-info-txt {
        display: flex;
        .detail-info-txt-title {
          font-size: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
          font-weight: 400;
          text-align: right;
          color: #3a485e;
          height: 14px;
          line-height: 14px;
          .fenge-icon {
            display: inline-block;
            width: 2px;
            height: 10px;
            background: #e1e5f4;
            margin: 0 12px;
          }
        }
        .detail-info-txt-info {
          font-size: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #191919;
          height: 14px;
          line-height: 14px;
          .zwx-button-text-26 {
            height: 14px !important;
            line-height: 14px !important;
          }
        }
      }
    }
  }
}

/deep/.coursewareone-dialog .el-radio.zwx-radio-course > .el-radio__input + .el-radio__label {
  display: none;
}
/deep/.courseware-dialog .el-dialog__header {
  box-shadow: none !important;
}
/deep/.courseware-dialog .el-table__body-wrapper {
  max-height: 274px !important;
}

/deep/.courseware-dialog .el-scrollbar__wrap {
  margin-right: -12px !important;
}

.student-management /deep/.el-form-item .el-form-item__content {
  display: block !important;
}
.zwx-select-student /deep/.el-input__inner {
  width: 200px !important;
}
/deep/.courseware-dialog .el-dialog {
  width: 800px !important;
  min-width: 800px !important;
}
.bindQrCodeDialog /deep/.el-dialog {
  width: fit-content;
  min-width: auto !important;
}

.update-training-info-dialog /deep/ .el-dialog {
  min-width: 640px !important;
  width: 640px !important;
}
.zwxlink {
  /deep/ .el-link {
    margin-top: inherit !important;
  }
}
</style>
