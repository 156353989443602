<template>
  <div class="search-input">
    <el-popover placement="bottom-start" popper-class="search-input-css" title="" width="200" trigger="manual" v-model="visible">
      <slot />
      <el-input slot="reference" class="zwx-input" style="width: 460px!important;" :placeholder="placeholder" v-model.trim="inputValue" maxlength="100" @blur="inputBlur" :disabled="ifSearchInputDisabled">
        <i v-if="!ifDelIcon" slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer;" @click="handlerClick"></i>
        <i v-if="ifDelIcon" slot="suffix" class="el-input__icon el-icon-delete" style="cursor: pointer;" @click="handlerDel"></i>
      </el-input>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'searchInput',
  model: {
    prop: 'value',
    event: 'propEvent',
  },
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '请输入',
    },
    ifDelIcon: {
      type: Boolean,
      default: false,
    },
    ifSearchInputDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      inputValue: '',
      list: [],
    }
  },
  computed: {},
  watch: {
    inputValue(val) {
      this.$emit('propEvent', val)
    },
  },
  created() {},
  mounted() {},
  methods: {
    handlerClick() {
      if (this.inputValue) {
        this.$emit('clickSearchIcon', this.inputValue)
      }
    },
    handlerDel() {
      this.$system.msgbox('', '提示', '是否确认清空？', '确定', '取消', () => {
        this.inputValue = ''
        this.$emit('clickDelIcon')
      })
    },
    inputBlur() {
      this.visible = false
    },
  },
}
</script>

<style scoped lang="less">
.flex-row {
  display: flex;
  align-items: center;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.search-input {
  .el-icon-search:before {
    color: #409eff;
  }
  .el-icon-delete:before {
    color: #409eff;
  }

  /deep/.el-input--suffix .el-input__inner {
    border: 1px solid #a8bbcf;
    border-radius: 2px;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    color: #191919;
    padding-left: 6px;
  }
  /deep/.el-input--suffix .el-input__icon {
    line-height: 32px;
  }
}
</style>
<style lang="less">
.search-input-css {
  margin-top: 0 !important;
  width: 420px !important;
}
</style>
