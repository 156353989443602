<template>
  <div class="employer-basic-management">
    <el-form class="zwx-form edit-form" ref="employerBasicForm" key="employerBasicForm" label-position="right" :model="employerBasicForm" :rules="employerBasicFormRules" @submit.native.prevent>
      <base-headline title="基本信息" class="no-top" />
      <div style="border-bottom: 1px solid #D5D9E0">
        <div class="edit-row">
          <el-form-item label-width="82px" label="单位名称：" prop="employerBasic.unitName">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.unitName" style="width:300px !important" maxlength="100" placeholder="" />
          </el-form-item>

          <el-form-item label-width="110px" label="社会信用代码：" prop="employerBasic.creditCode">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.creditCode" style="width:200px !important" maxlength="18" placeholder="" disabled />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="95px" label="单位简称：" prop="employerBasic.unitShortName">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.unitShortName" style="width:146px !important" maxlength="6" placeholder="" clearable />
          </el-form-item>
          <el-form-item label-width="95px" label="法定代表人：" prop="employerBasic.legalPerson">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.legalPerson" style="width:130px !important" maxlength="50" placeholder="" clearable />
          </el-form-item>
          <el-form-item label-width="170px" label="法定代表人联系电话：" prop="employerBasic.legalPersonPhone">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.legalPersonPhone" style="width:130px !important" maxlength="15" placeholder="" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="95px" label="主要负责人：" prop="employerBasic.contactPerson">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.contactPerson" style="width:297px !important" maxlength="50" placeholder="" clearable />
          </el-form-item>
          <el-form-item label-width="170px" label="主要负责人联系电话：" prop="employerBasic.contact">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.contact" style="width:227px !important" maxlength="15" placeholder="" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="82px" label="企业规模：" prop="employerBasic.enterpriseScale">
            <el-radio-group class="zwx-radio-group" v-model="employerBasicForm.employerBasic.enterpriseScale">
              <el-radio class="zwx-radio" :label="1">大</el-radio>
              <el-radio class="zwx-radio" :label="2">中</el-radio>
              <el-radio class="zwx-radio" :label="3">小</el-radio>
              <el-radio class="zwx-radio" :label="4">微</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="82px" label="所属行业：" prop="employerBasic.industryName">
            <div>
              <el-input class="zwx-input zwx-occ-category-input" readonly="readonly" v-model.trim="employerBasicForm.employerBasic.industryName" style="width: 300px !important;" placeholder="" :read-only="true" @focus="msDeclareInfoCategoryDialog">
                <template slot="append">
                  <span><i class="el-icon-search"></i></span>
                </template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label-width="82px" label="经济类型：" prop="employerBasic.economicCode">
            <cascader-single class="cascader-single-economic" v-model="employerBasicForm.employerBasic.economicCode" ref="cascader" :simpleCodeList="msEmployerBasicFormExp.economicSimpleCodeList" expandTrigger="hover" codeTypeCode="1015" @change="economicChange"></cascader-single>
          </el-form-item>
        </div>

        <div class="edit-row">
          <el-form-item label-width="82px" label="注册地区：" prop="employerBasic.registerZone">
            <zwx-select-area class="cascader-area-register-zone" ref="registerZoneArea" :zoneCode12From="msEmployerBasicFormExp.registerZoneFrom" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" @change="registerZoneChange" />
          </el-form-item>
          <el-form-item label-width="82px" label="注册地址：" prop="employerBasic.registerAddr">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.registerAddr" style="width:494px !important" maxlength="400" placeholder="" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="82px" label="经营地区：" prop="employerBasic.bizZone">
            <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" :zoneCode12From="msEmployerBasicFormExp.bizZoneFrom" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" @change="bizZoneChange" />
          </el-form-item>
          <el-form-item class="msmap" label-width="110px" label="作业场所地址：" prop="employerBasic.workplaceAddr">
            <baidu-map-dialog v-model="employerBasicForm.employerBasic.workplaceAddr" ref="mapCoverDialogRef" :lng="employerBasicForm.employerBasic.lng" :lat="employerBasicForm.employerBasic.lat" :fullZoneName="fullZoneName" @submit="employerMapDialogSubmit" @msNotify="baiduMapMsNotify"></baidu-map-dialog>
            <!-- <baidu-map-dialog v-model="employerBasicForm.employerBasic.workplaceAddr" ref="mapCoverDialogRef" :lng="112" :lat="122" :fullZoneName="fullZoneName" @submit="employerMapDialogSubmit" @msNotify="baiduMapMsNotify"></baidu-map-dialog> -->
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="138px" label="职业卫生管理人员：" prop="employerBasic.healthManagement">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.healthManagement" maxlength="50" placeholder="" clearable />
          </el-form-item>
          <el-form-item label-width="82px" label="联系电话：" prop="employerBasic.managementPhone">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.managementPhone" style="width:157px !important" maxlength="15" placeholder="" clearable />
          </el-form-item>
        </div>
        <!-- <div class="edit-row">
          <attachmen-upload folder="/employer/basic" v-model="employerBasicForm.employerBasic.fileList" label="营业执照：" labelwidth="82px" fileProp="employerBasic.fileList"></attachmen-upload>
        </div> -->
      </div>
      <base-headline title="主要产品" class="no-top" :class="{ 'no-bottom': !employerBasicForm.employerBasic.ifClassifiedUnit }">
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addProduct" :disabled="employerBasicForm.employerBasic.ifClassifiedUnit">添加</el-button>
        <el-checkbox v-model="employerBasicForm.employerBasic.ifClassifiedUnit" @change="updateUnit" style="margin-left: 14px">军工等涉密企业</el-checkbox>
        <div class="ms-el-message ms-el-message--warning" style="display: inline-block;margin-left: 10px;">
          <i class="el-icon-warning"></i>
          非生产型企业，如医院、饭店等，主要产品项名称及计量单位可填“无”，年产量填“0”。
        </div>
      </base-headline>
      <el-table v-if="!employerBasicForm.employerBasic.ifClassifiedUnit" id="productListTable" class="zwx-table" style="width: 100%" row-key="rid" key="productListTable" :data="employerBasicForm.productList" stripe border>
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column prop="productName" label="产品名称" width="350" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'productList.' + scope.$index + '.productName'" :rules="employerBasicFormRules.productName">
              <el-input class="zwx-input" v-model="scope.row.productName" placeholder="请输入" maxlength="50" clearable />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="dosage" label="年产量" width="180" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'productList.' + scope.$index + '.annualOutput'" :rules="employerBasicFormRules.annualOutput">
              <el-input class="zwx-input" v-model="scope.row.annualOutput" @input="$validate.nonnegativeDouble($data['employerBasicForm']['productList'][scope.$index], 'annualOutput', -1, 2)" placeholder="请输入" maxlength="50" clearable />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="计量单位" width="180" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'productList.' + scope.$index + '.unit'" :rules="employerBasicFormRules.unit">
              <el-autocomplete class="zwx-input" v-model.trim="scope.row.unit" value-key="codeName" :fetch-suggestions="unitAutocompleteQuery" :maxlength="10" clearable></el-autocomplete>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" header-align="left" align="left" min-width="80">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="danger" @click="delProduct(scope.$index)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <base-headline title="人员基本情况" class="no-top"></base-headline>
      <div>
        <div class="edit-row">
          <el-form-item label-width="115px" label="在册职工总数：" prop="employerBasic.totalStaffNum">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.totalStaffNum" style="width:100px !important" maxlength="9" placeholder="" />
            <div class="person-unit">人</div>
          </el-form-item>
          <el-form-item label-width="183px" label="在册接害总人数：" prop="employerBasic.workerNum">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.workerNum" style="width:100px !important" maxlength="9" placeholder="" />
            <div class="person-unit">人</div>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="115px" label="外委人员总数：" prop="employerBasic.outsourcingNum">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.outsourcingNum" @input="$validate.nonnegativeInteger($data['employerBasicForm']['employerBasic'], 'outsourcingNum')" style="width:100px !important" maxlength="9" placeholder="" />
            <div class="person-unit">人</div>
          </el-form-item>
          <el-form-item label-width="185px" label="外委接害人数：" prop="employerBasic.outsourcingVictimsNum">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.outsourcingVictimsNum" @input="$validate.nonnegativeInteger($data['employerBasicForm']['employerBasic'], 'outsourcingVictimsNum')" style="width:100px !important" maxlength="9" placeholder="" />
            <div class="person-unit">人</div>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="170px" label="接害总人数（含外委）：">
            <span>{{ totalVictimsNum }} 人</span>
            <!-- <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.totalVictimsNum" @input="$validate.nonnegativeInteger($data['employerBasicForm']['employerBasic'], 'totalVictimsNum')" style="width:100px !important" maxlength="9" placeholder="" /> -->
            <!-- <div class="person-unit">人</div> -->
          </el-form-item>
          <el-form-item label-width="173px" label="累计确诊职业病人数：" prop="employerBasic.confirmedDiseaseNum">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.confirmedDiseaseNum" @input="$validate.nonnegativeInteger($data['employerBasicForm']['employerBasic'], 'confirmedDiseaseNum')" style="width:100px !important" maxlength="9" placeholder="" />
            <div class="person-unit">人</div>
          </el-form-item>
        </div>
      </div>
      <base-headline title="账号信息" />
      <div style="padding-top:16px;padding-left:16px;">
        <div>
          <el-form-item label-width="82px" label="姓名：" prop="employerBasic.userName">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.userName" style="width:159px !important" maxlength="50" placeholder="" clearable />
          </el-form-item>
          <el-form-item label-width="87px" label="账号：" prop="employerBasic.userNo">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.userNo" onkeyup="value=value.replace(/[^\a-\z\A-\Z0-9]/g,'')" style="width:200px !important" maxlength="50" placeholder="" clearable />
          </el-form-item>
          <el-form-item label-width="95px" label="手机号：" prop="employerBasic.mobileTel">
            <el-input class="zwx-input" v-model="employerBasicForm.employerBasic.mobileTel" style="width:157px !important" maxlength="20" placeholder="" clearable />
          </el-form-item>
        </div>
        <div>
          <el-form-item label-width="82px" label="证件类型：" prop="employerBasic.cardType">
            <cascader-single class="cascader-single-common" ref="idTypeCascader" v-model="employerBasicForm.employerBasic.cardType" :simpleCodeList="msEmployerBasicFormExp.idTypeSimpleCodeList" expandTrigger="hover" codeTypeCode="1003"></cascader-single>
          </el-form-item>
          <el-form-item label-width="87px" label="证件号码：" prop="employerBasic.idc">
            <el-input class="zwx-input " placeholder="请输入" style="width: 200px !important;" v-model.trim="employerBasicForm.employerBasic.idc" clearable />
          </el-form-item>
        </div>
      </div>
    </el-form>
    <base-headline v-if="childTbEmployerExpList.length != 0" title="组织架构管理" class="no-top"></base-headline>
    <div class="organization" v-if="childTbEmployerExpList.length != 0">
      <div class="organization-item" @click="jumpMenu(item.unitUid)" v-for="item in childTbEmployerExpList" :key="item.rid">
        <div class="title">
          <div class="title-unit">
            <span style="color:#535E6F">{{ item.unitName }}</span>
            <span v-if="item.enterpriseScale">{{ enterpriseScaleList[item.enterpriseScale] }}</span>
          </div>
          <div style="color:#535E6F;">{{ item.creditCode }}</div>
        </div>
        <div class="body">
          <div class="body-row">
            <img src="../../../assets/ico/info/type.png" alt="" srcset="" />
            <span>行业分类：{{ item.industryName }}</span>
          </div>
          <div class="body-row" style="padding-left:19px;">
            <span>经济类型：{{ item.economicName }}</span>
          </div>
          <div class="body-row">
            <img src="../../../assets/ico/info/ic_dizhi.png" alt="" srcset="" />
            <span>注册地址：{{ item.registerAddr }}</span>
          </div>
          <div class="body-row" style="padding-left:19px;">
            <span>经营地址：{{ item.workplaceAddr }}</span>
          </div>
          <div class="body-row">
            <img src="../../../assets/ico/info/ic_faren.png" alt="" srcset="" />
            <span>
              {{ item.legalPerson }}
              <i v-if="item.contact != undefined">（{{ $zwxSm.sm4JsDecrypt(item.contact) }}）</i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="saveEmployerinfo" :disabled="saveButtonDisabled">保存</el-button>
    </fixed-footer>
    <!--危害因素弹窗-->
    <base-dialog class="factor-select-dialog" ref="factorSelectDialogRef" title="添加危害因素" :pagination="true" @determine="confirmFactorDialog" @cancel="cancelFactorDialog" @close="closeFactorDialog" @currentPage="factorDialogSearchInit" :currentPage="factorDialog.factorSelectForm.currentPage" :pageSize="factorDialog.pageSize" :total="factorDialog.total">
      <el-form class="zwx-form edit-form" key="factorSelectForm" :model="factorDialog.factorSelectForm" label-position="right" @submit.native.prevent>
        <div class="edit-row">
          <el-form-item label="危害因素名称：" label-width="98px">
            <el-input class="zwx-input" style="width:150px" v-model.trim="factorDialog.factorSelectForm.hazardsName" placeholder="请输入危害因素名称" clearable></el-input>
          </el-form-item>
          <el-button class="zwx-button zwx-button-icontext-28" @click="factorDialogSearchInit(1)">查询</el-button>
        </div>
      </el-form>
      <div style="padding-bottom: 20px">
        <el-table class="zwx-table" row-key="rid" :data="factorDialog.factorList" highlight-current-row stripe border>
          <el-table-column label="选择" width="80" header-align="center">
            <template slot-scope="scope">
              <div style="text-align: center">
                <el-checkbox v-model="scope.row.checked0" @change="declareHazardSelect0(scope.row)"></el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="factor0.hazardsName" label="危害因素名称" header-align="left" align="left" min-width="400"></el-table-column>
          <el-table-column label="选择" width="80" header-align="center">
            <template slot-scope="scope">
              <div style="text-align: center">
                <el-checkbox v-model="scope.row.checked1" @change="declareHazardSelect1(scope.row)" v-if="$zwxBase.verifyIsNotBlank(scope.row.factor1)"></el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="factor1.hazardsName" label="危害因素名称" header-align="left" align="left" min-width="400"></el-table-column>
        </el-table>
      </div>
    </base-dialog>
    <!--行业分类弹窗-->
    <industry-select ref="msDeclareComponentCategory" :ifValidateIfLeaf="true" :interfaceName="'/basic/info'" @change="msDeclareComponentCategoryChange" />
  </div>
</template>

<script>
export default {
  name: 'EmployerBasic',
  data() {
    return {
      users: this.$store.state.users,
      api: this.$store.state.api,
      childTbEmployerExpList: [],
      expertFileExpList: [],
      employerBasicForm: {
        rid: '',
        employerBasic: {
          rid: '',
          uid: '',
          unitUid: '',
          unitName: '',
          cardType: '',
          idc: '',
          registerDate: '',
          creditCode: '',
          industryCode: '',
          industryFullCode: '',
          industryName: '',
          riskClassification: '',
          legalPerson: '',
          userNo: '',
          userName: '',
          unitShortName: '',
          mobileTel: '',
          contactPerson: '',
          contact: '',
          legalPersonPhone: '',
          economicCode: '',
          economicName: '',
          economicFullCode: '',
          registerZone: '',
          registerAddr: '',
          bizZone: '',
          managementPhone: '',
          workplaceAddr: '',
          enterpriseScale: '',
          healthManagement: '',
          ifClassifiedUnit: false,
          totalStaffNum: '',
          workerNum: '',
          outsourcingNum: '',
          totalVictimsNum: '',
          confirmedDiseaseNum: '',
          outsourcingVictimsNum: '',
          // fileList: [],
          lng: null,
          lat: null,
        },
        hazardList: [],
        productList: [],
      },
      msEmployerBasicFormExp: {
        saveButtonDisabled: true,
        msEmployerBasicFormJson: '',
        simpleCodeMap: null,
        idTypeSimpleCodeList: [],
        industrySimpleCodeList: [],
        economicSimpleCodeList: [],
        unitSimpleCodeList: [],
        registerZoneFrom: '',
        bizZoneFrom: '',
      },
      enterpriseScaleList: ['大型', '大型', '中型', '小型', '微型'],
      tsParameterExp: {},
      //地图选点
      employerBaiduMapParam: {
        positionState: 0, //当前定位状态（0：未定位，1：已定位，2：重新修改）
      },
      employerBasicFormRules: {
        'employerBasic.unitName': [{ required: true, message: '请输入单位名称', trigger: ['blur'] }],
        'employerBasic.creditCode': [{ required: true, message: '请输入社会信用代码', trigger: ['blur'] }],
        'employerBasic.unitShortName': [{ required: true, message: '请输入单位简称', trigger: ['blur'] }],
        'employerBasic.cardType': [{ required: true, message: '请选择证件类型', trigger: 'change' }],
        'employerBasic.idc': [
          { required: true, message: '请输入证件号码', trigger: 'change' },
          { validator: this.validatorIdCard, trigger: 'blur' },
        ],
        'employerBasic.enterpriseScale': [{ required: true, message: '请选择企业规模', trigger: ['change'] }],
        'employerBasic.industryName': [{ required: true, message: '请选择所属行业', trigger: ['change'] }],
        'employerBasic.legalPerson': [{ required: true, message: '请输入法定代表人', trigger: ['blur'] }],
        'employerBasic.legalPersonPhone': [
          { required: true, message: '请输入联系电话', trigger: ['blur'] },
          { validator: this.validateLegalPersonPhone, trigger: ['blur'] },
        ],
        'employerBasic.mobileTel': [
          { required: true, message: '请输入手机号码', trigger: ['change', 'blur'] },
          { validator: this.validateMobile, trigger: ['blur'] },
        ],
        'employerBasic.contactPerson': [{ required: true, message: '请输入主要负责人', trigger: ['blur'] }],
        'employerBasic.userName': [{ required: true, message: '请输入姓名', trigger: ['blur'] }],
        'employerBasic.userNo': [{ required: true, message: '请输入账号', trigger: ['blur'] }],
        'employerBasic.economicCode': [{ required: true, message: '请选择经济类型', trigger: ['blur', 'change'] }],
        'employerBasic.registerZone': [
          { required: true, message: '请选择注册地区', trigger: ['blur', 'change'] },
          { validator: this.validateRegisterZone, trigger: ['blur', 'change'] },
        ],
        'employerBasic.registerAddr': [{ required: true, message: '请输入注册地址', trigger: ['blur'] }],
        'employerBasic.bizZone': [
          { required: true, message: '请选择经营地区', trigger: ['blur', 'change'] },
          { validator: this.validateBizZone, trigger: ['blur', 'change'] },
        ],
        'employerBasic.managementPhone': [
          { required: true, message: '请输入联系电话', trigger: ['blur'] },
          { validator: this.validateLegalPersonPhone, trigger: ['blur'] },
        ],
        'employerBasic.workplaceAddr': [{ required: true, message: '请输入作业场所地址', trigger: ['blur'] }],
        'employerBasic.contact': [
          { required: true, message: '请输入联系电话', trigger: ['blur'] },
          { validator: this.validateLegalPersonPhone, trigger: ['blur'] },
        ],
        'employerBasic.healthManagement': [{ required: true, message: '请输入职业卫生管理人员', trigger: ['blur'] }],
        // 'employerBasic.fileList': [{ required: true, message: '请上传营业执照', trigger: ['change', 'blur'] }],
        productName: [{ validator: this.validateProductName, trigger: ['blur'] }],
        annualOutput: [{ validator: this.validateAnnualOutput, trigger: ['blur'] }],
        unit: [{ validator: this.validateUnit, trigger: ['blur', 'change'] }],
        'employerBasic.totalStaffNum': [{ required: true, message: '请输入在册职工总数', trigger: ['change', 'blur'] }],
        'employerBasic.workerNum': [{ required: true, message: '请输入在册接害总人数', trigger: ['change', 'blur'] }],
        'employerBasic.outsourcingNum': [{ required: true, message: '请输入外委人员总数', trigger: ['change', 'blur'] }],
        'employerBasic.outsourcingVictimsNum': [{ required: true, message: '请输入外委接害人数', trigger: ['change', 'blur'] }],
        'employerBasic.confirmedDiseaseNum': [{ required: true, message: '请输入累计职业病确诊人数', trigger: ['change', 'blur'] }],
      },
      factorDialog: {
        pageSize: 18,
        total: 0,
        factorList: [],
        factorSelectForm: {
          currentPage: 1,
          hazardsName: '',
          selectedFactors: [],
        },
      },
      fullZoneName: '',
    }
  },
  watch: {},
  computed: {
    totalVictimsNum() {
      let { workerNum, outsourcingVictimsNum } = this.employerBasicForm.employerBasic
      return parseInt(workerNum || 0) + parseInt(outsourcingVictimsNum || 0)
    },
    saveButtonDisabled() {
      if (JSON.stringify(this.employerBasicForm) !== this.msEmployerBasicFormExp.msEmployerBasicFormJson) {
        return false
      } else {
        return true
      }
    },
    /**
     * 地图标记样式
     */
    getEmployerPositionStyle: function() {
      let styleName = this.employerBaiduMapParam.positionState === 2 ? 'color: #E02020 !important' : this.employerBaiduMapParam.positionState === 1 ? 'color: #37C516 !important' : 'color: #3E71F7 !important'
      return styleName
    },
    /**
     * 地图标记悬浮文本
     */
    getEmployerPositionTip() {
      let tip = '点击定位'
      if (this.employerBaiduMapParam.positionState === 2) {
        tip = '重新定位'
      } else if (this.employerBaiduMapParam.positionState === 1) {
        tip = this.employerBasicForm.employerBasic.lng + '°E' + ',' + this.employerBasicForm.employerBasic.lat + '°N'
      }
      return tip
    },
  },
  created() {
    this.msEmployerBasicFormExp.registerZoneFrom = '000000000000'
    if (this.$zwxBase.verifyIsNotBlank(this.$route.query.employerUuid)) {
      this.employerBasicForm.unitUid = this.$route.query.employerUuid
    }
  },
  mounted() {
    this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('1003,1015,2002', data => {
      this.msEmployerBasicFormExp.economicSimpleCodeList = data['1015']
      this.msEmployerBasicFormExp.unitSimpleCodeList = data['2002']
      this.msEmployerBasicFormExp.idTypeSimpleCodeList = data['1003']
      // 查询经验地区系统参数
      this.getEmployerBizZoneParameter()
      // this.msEmployerBasicFormExp.bizZoneFrom = "440000000000"
      this.getIndustryDataSource()
    })
  },
  methods: {
    updateUnit(val) {
      if (val) {
        this.employerBasicForm.productList = []
      }
      console.log(val, 'val')
    },
    /**
     * 当证件类型为身份证时校验证件号码
     */
    validatorIdCard(rule, value, callback) {
      let idType = this.employerBasicForm.employerBasic.cardType
      if (this.$zwxBase.verifyIsBlank(value)) {
        return callback(new Error('请输入证件号码'))
      }
      if (this.$zwxBase.verifyIsNotBlank(idType) && idType === '10030001') {
        this.$validate.idcCode(rule, value, callback)
      }
      return callback()
    },
    jumpMenu(unitUid) {
      /**
       * 跳转对应页面
       */
      this.$router.push({ name: 'StaffHealthArchivePage', params: { employerUuid: unitUid, ifBtnShow: true } })
      return
      var handlerType = ''
      handlerType = 'menuJump'
      top.postMessage(
        {
          handlerType: handlerType,
          params: {
            name: '8734h8242bjyglkl',
            unitUid,
          },
        },
        '*'
      )
    },
    /**
     * 获取基本信息 经营地区系统参数
     */
    getEmployerBizZoneParameter() {
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/basic/info/getEmployerBizZoneParameter-1',
        null,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.tsParameterExp = data.tsParameterExp
            if (this.$zwxBase.verifyIsNotBlank(this.tsParameterExp.parameterValue)) {
              this.msEmployerBasicFormExp.bizZoneFrom = this.tsParameterExp.parameterValue
            } else {
              this.msEmployerBasicFormExp.bizZoneFrom = '000000000000'
            }
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 获取行业数据源
     */
    getIndustryDataSource() {
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/basic/info/getIndustryClassificationList-1',
        null,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            let industryClassificationList = data.industryClassificationList
            if (this.$zwxBase.verifyIsNotBlank(industryClassificationList)) {
              industryClassificationList.forEach(item => {
                item.codeTypeCode = '2001'
              })
            }
            this.msEmployerBasicFormExp.industrySimpleCodeList = industryClassificationList || []
            this.getEmployerBasic()
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    getEmployerBasic() {
      let data = {
        unitUid: this.employerBasicForm.unitUid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/basic/info/getEmployerBasic-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.childTbEmployerExpList = data.childTbEmployerExpList || []
            Object.assign(this.employerBasicForm.employerBasic, data.employerExp)
            this.employerBasicForm.employerBasic.uid = data.tsUserInfoExp.uid
            this.employerBasicForm.employerBasic.userName = data.tsUserInfoExp.userName
            this.employerBasicForm.employerBasic.userNo = data.tsUserInfoExp.userNo
            this.employerBasicForm.employerBasic.mobileTel = this.$zwxSm.sm4JsDecrypt(data.tsUserInfoExp.mobileTel)
            this.employerBasicForm.employerBasic.cardType = data.tsUserInfoExp.cardType
            this.employerBasicForm.employerBasic.idc = this.$zwxSm.sm4JsDecrypt(data.tsUserInfoExp.idc)
            this.employerBasicForm.employerBasic.lng = Number(this.employerBasicForm.employerBasic.lng)
            this.employerBasicForm.employerBasic.lat = Number(this.employerBasicForm.employerBasic.lat)
            this.employerBasicForm.employerBasic.contact = this.$zwxSm.sm4JsDecrypt(this.employerBasicForm.employerBasic.contact)
            this.employerBasicForm.employerBasic.legalPersonPhone = this.$zwxSm.sm4JsDecrypt(this.employerBasicForm.employerBasic.legalPersonPhone)
            this.employerBasicForm.employerBasic.managementPhone = this.$zwxSm.sm4JsDecrypt(this.employerBasicForm.employerBasic.managementPhone)
            // this.employerBasicForm.employerBasic.fileList = data.employerExp.businessLicense ? [{ filePath: data.employerExp.businessLicense }] : []
            if (this.$zwxBase.verifyIsNotBlank(data.tbEmployerHazardExposureExps)) {
              this.employerBasicForm.hazardList = this.$zwxBase.multidimensionalArrayDeal(data.tbEmployerHazardExposureExps, 3)
            }
            this.employerBasicForm.productList = data.majorProductExps
            this.$refs.registerZoneArea.initSearch(this.employerBasicForm.employerBasic.registerZone)
            this.$refs.bizZoneArea.initSearch(this.employerBasicForm.employerBasic.bizZone)
            this.msEmployerBasicFormExp.msEmployerBasicFormJson = JSON.stringify(this.employerBasicForm)
            if (this.$zwxBase.verifyIsBlank(this.employerBasicForm.employerBasic.lng) && this.$zwxBase.verifyIsBlank(this.employerBasicForm.employerBasic.lat)) {
              this.employerBaiduMapParam.positionState = 0
            } else {
              this.employerBaiduMapParam.positionState = 1
            }
            this.$nextTick(() => {
              this.$refs['employerBasicForm'].clearValidate()
            })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    // 行业分类
    msDeclareInfoCategoryDialog() {
      this.$refs.msDeclareComponentCategory.show(true)
    },
    msDeclareComponentCategoryChange() {
      // 职业病危害风险分类
      this.employerBasicForm.employerBasic.riskClassification = this.$refs.msDeclareComponentCategory.msCategoryGet().riskClassification
      this.employerBasicForm.employerBasic.industryCode = this.$refs.msDeclareComponentCategory.msCategoryGet().codeNo
      this.employerBasicForm.employerBasic.industryFullCode = this.$refs.msDeclareComponentCategory.msCategoryGet().levelCodeNo
      this.$set(this.employerBasicForm.employerBasic, 'industryName', this.$refs.msDeclareComponentCategory.msCategoryGet().industryName)
      this.searchRisk(this.employerBasicForm.employerBasic.industryFullCode)
    },
    // 根据所选行业分类查询职业病危害程度
    searchRisk(levelCodeNo) {
      this.$system.get(
        this.api + '/oh/repository/getLevelInfoByIndustryNo-0',
        { levelCodeNo },
        true,
        true,
        data => {
          if (data.type === '00') {
            this.employerBasicForm.employerBasic.riskClassification = data.levelRisk.riskDegree
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$msSystem.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        this.error
      )
    },
    /**
     * 注册地区校验
     */
    validateRegisterZone(rule, value, callback) {
      if (this.$zwxBase.verifyIsBlank(this.$refs.registerZoneArea.getChooseArea())) {
        return callback(new Error('请选择'))
      }
      let zoneType = this.$refs.registerZoneArea.getChooseArea().zoneType
      if (this.$zwxBase.verifyIsNotBlank(zoneType) && zoneType < 3) {
        return callback(new Error('请选择到区县级别/街道级别'))
      }
      return callback()
    },
    /**
     * 经营地区校验
     */
    validateBizZone(rule, value, callback) {
      if (this.$zwxBase.verifyIsBlank(this.$refs.bizZoneArea.getChooseArea())) {
        return callback(new Error('请选择'))
      }
      let zoneType = this.$refs.bizZoneArea.getChooseArea().zoneType
      let childNum = this.$refs.bizZoneArea.getChooseArea().childNum
      if (this.$zwxBase.verifyIsNotBlank(zoneType) && this.$zwxBase.verifyIsNotBlank(childNum)) {
        if (childNum != 0) {
          return callback(new Error('请选择到最下级'))
        } else {
          if (zoneType > 4) {
            return callback(new Error('请选择到街道级'))
          }
        }
      }
      return callback()
    },
    /**
     * 电话校验
     */
    validateLegalPersonPhone(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        this.$validate.mbPeLdline(rule, value, callback)
      }
      return callback()
    },
    /**
     * 邮编校验
     */
    validatePostcode(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        this.$validate.zipCode(rule, value, callback)
      }
      return callback()
    },
    /**
     * 产品名称校验
     */
    validateProductName(rule, value, callback) {
      let i = rule.field.split('.')[1]
      let productObj = this.employerBasicForm.productList[i]
      if (this.$zwxBase.verifyIsBlank(productObj.productName)) {
        return callback(new Error('请输入产品名称'))
      }
      return callback()
    },
    /**
     * 年产量校验
     */
    validateAnnualOutput(rule, value, callback) {
      let i = rule.field.split('.')[1]
      let productObj = this.employerBasicForm.productList[i]
      if (this.$zwxBase.verifyIsBlank(productObj.annualOutput)) {
        return callback(new Error('请输入年产量'))
      }
      return callback()
    },
    /**
     * 计量单位校验
     */
    validateUnit(rule, value, callback) {
      let i = rule.field.split('.')[1]
      let productObj = this.employerBasicForm.productList[i]
      if (this.$zwxBase.verifyIsBlank(productObj.unit)) {
        return callback(new Error('请输入计量单位'))
      }
      return callback()
    },
    /**
     * 经济类型选择改变回调
     */
    economicChange(value) {
      let economicArr = this.msEmployerBasicFormExp.economicSimpleCodeList.filter(item => item.codeNo == value)
      if (this.$zwxBase.verifyIsNotBlank(economicArr)) {
        this.employerBasicForm.employerBasic.economicFullCode = economicArr[0].codeLevelNo
        this.employerBasicForm.employerBasic.economicName = economicArr[0].codeName
      }
    },
    /**
     * 注册地区改变回调
     */
    registerZoneChange() {
      if (!this.$zwxBase.verifyIsBlank(this.$refs.registerZoneArea.getChooseArea())) {
        this.employerBasicForm.employerBasic.registerZone = this.$refs.registerZoneArea.getChooseArea().zoneCode12
      }
    },
    /**
     * 经营地区改变回调
     */
    bizZoneChange() {
      let fullName = undefined
      if (!this.$zwxBase.verifyIsBlank(this.$refs.bizZoneArea.getChooseArea())) {
        this.employerBasicForm.employerBasic.bizZone = this.$refs.bizZoneArea.getChooseArea().zoneCode12
        fullName = this.$refs.bizZoneArea.getChooseArea().fullName
      }

      if (this.$zwxBase.verifyIsNotBlank(fullName) && this.$zwxBase.verifyIsBlank(this.employerBasicForm.employerBasic.workplaceAddr)) {
        this.employerBasicForm.employerBasic.workplaceAddr = fullName.replaceAll('_', '')
      }
      this.fullZoneName = this.$refs.bizZoneArea.getChooseArea() == null ? null : this.$refs.bizZoneArea.getChooseArea().fullName
      this.employerBasicForm.employerBasic.lng = null
      this.employerBasicForm.employerBasic.lat = null
      this.employerBaiduMapParam.positionState = 0
    },
    /**
     * 作业场所地址change事件
     */
    workArrChange(value) {
      this.employerBaiduMapParam.positionState = 0
      if (this.$zwxBase.verifyIsNotBlank(value) && (this.$zwxBase.verifyIsNotBlank(this.employerBasicForm.employerBasic.lat) || this.$zwxBase.verifyIsNotBlank(this.employerBasicForm.employerBasic.lng))) {
        this.employerBaiduMapParam.positionState = 2
      }
    },
    /**
     * 提交危害因素添加弹框
     */
    confirmFactorDialog() {
      let hazardList = JSON.parse(JSON.stringify(this.employerBasicForm.hazardList))
      hazardList = this.$zwxBase.arrayDeal(hazardList)
      if (this.$zwxBase.verifyIsNotBlank(this.factorDialog.factorSelectForm.selectedFactors)) {
        this.factorDialog.factorSelectForm.selectedFactors.forEach(item => {
          let data = {
            hazardsCode: item.codeNo,
            supervisionRequirement: false,
            hazardsName: item.hazardsName,
            hazardsSort: item.hazardsSort,
            contactNumber: '',
          }
          hazardList.push(data)
        })
        this.employerBasicForm.hazardList = this.$zwxBase.multidimensionalArrayDeal(hazardList, 3)
      }
      this.$refs.factorSelectDialogRef.show(false)
    },
    /**
     * 取消危害因素添加弹框
     */
    cancelFactorDialog() {
      this.$refs.factorSelectDialogRef.show(false)
    },
    /**
     * 关闭危害因素弹框回调
     */
    closeFactorDialog() {
      this.factorDialog.pageSize = 18
      this.factorDialog.total = 0
      this.factorDialog.factorList = []
      this.factorDialog.factorSelectForm.currentPage = 1
      this.factorDialog.factorSelectForm.hazardsName = ''
      this.factorDialog.factorSelectForm.selectedFactors = []
    },
    /**
     * 危害因素弹框列表查询
     */
    factorDialogSearchInit(currentPage) {
      this.factorDialog.factorSelectForm.currentPage = currentPage
      let selectedFactorList = []
      if (this.$zwxBase.verifyIsNotBlank(this.employerBasicForm.hazardList)) {
        selectedFactorList = this.$zwxBase.arrayDeal(this.employerBasicForm.hazardList)
      }
      let data = {
        currentPage: this.factorDialog.factorSelectForm.currentPage,
        hazardsName: this.factorDialog.factorSelectForm.hazardsName,
        selectedFactorList: selectedFactorList,
      }
      this.$system.postJson(
        this.api + '/ohts/basic/getHazardsFactorsByDeclareEditHazard-1',
        data,
        true,
        true,
        data => {
          if (data.type == '00') {
            this.factorDialog.factorList = data.tableList ? data.tableList : []
            let selectedFactors = this.factorDialog.factorSelectForm.selectedFactors
            if (this.$zwxBase.verifyIsNotBlank(selectedFactors)) {
              for (let i in selectedFactors) {
                for (let j in this.factorDialog.factorList) {
                  if (this.$zwxBase.verifyIsNotBlank(this.factorDialog.factorList[j].factor0) && selectedFactors[i].codeNo === this.factorDialog.factorList[j].factor0.codeNo) {
                    this.factorDialog.factorList[j].checked0 = true
                  }
                  if (this.$zwxBase.verifyIsNotBlank(this.factorDialog.factorList[j].factor1) && selectedFactors[i].codeNo === this.factorDialog.factorList[j].factor1.codeNo) {
                    this.factorDialog.factorList[j].checked1 = true
                  }
                }
              }
            }
            this.factorDialog.pageSize = data.pageSize ? data.pageSize : 18
            this.factorDialog.total = data.total
            this.$refs.factorSelectDialogRef.show(true)
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 危害因素弹框危害因素选择回调
     */
    declareHazardSelect0(row) {
      if (row.checked0) {
        this.factorDialog.factorSelectForm.selectedFactors.push(row.factor0)
      }
      if (!row.checked0) {
        let index = this.getSelectedFactorsIndex(row.factor0)
        this.factorDialog.factorSelectForm.selectedFactors.splice(index, 1)
      }
    },
    /**
     * 危害因素弹框危害因素选择回调
     */
    declareHazardSelect1(row) {
      if (row.checked1) {
        this.factorDialog.factorSelectForm.selectedFactors.push(row.factor1)
      }
      if (!row.checked1) {
        let index = this.getSelectedFactorsIndex(row.factor1)
        this.factorDialog.factorSelectForm.selectedFactors.splice(index, 1)
      }
    },
    /**
     * 获取选择危害因素下标
     */
    getSelectedFactorsIndex(factor) {
      if (this.$zwxBase.verifyIsBlank(this.factorDialog.factorSelectForm.selectedFactors)) {
        return -1
      }
      let len = this.factorDialog.factorSelectForm.selectedFactors.length
      for (let i = 0; i < len; i++) {
        if (factor.codeNo == this.factorDialog.factorSelectForm.selectedFactors[i].codeNo) {
          return parseInt(i)
        }
      }
      return -1
    },
    /**
     * 添加产品
     */
    addProduct() {
      let data = {
        uuid: this.$system.uuid(),
        productName: '',
        annualOutput: '',
        unit: '',
      }
      this.employerBasicForm.productList.push(data)
      this.$nextTick(() => {
        let height = document.getElementsByClassName('employer-basic-management')[0].clientHeight + 56
        document.getElementById('productListTable').scrollIntoView(height)
      })
    },
    /**
     * 删除产品
     */
    delProduct(index) {
      this.employerBasicForm.productList.splice(index, 1)
    },
    /**
     * 计量单位
     */
    unitAutocompleteQuery: function(queryString, cb) {
      var unitSimpleCodeList = this.msEmployerBasicFormExp.unitSimpleCodeList
      var results = queryString ? unitSimpleCodeList.filter(this.unitAutocompleteFilter(queryString)) : unitSimpleCodeList
      cb(results)
    },
    /**
     * 计量单位过滤器
     */
    unitAutocompleteFilter: function(queryString) {
      return function(obj) {
        return obj.codeName.indexOf(queryString) === 0
      }
    },
    /**
     * 保存企业基本信息
     */
    saveEmployerinfo() {
      this.$refs.employerBasicForm.validate(valid => {
        if (valid) {
          let tbEmployerHazardExposureExps = []
          if (this.$zwxBase.verifyIsNotBlank(this.employerBasicForm.hazardList)) {
            tbEmployerHazardExposureExps = this.$zwxBase.arrayDeal(this.employerBasicForm.hazardList)
          }
          let data = {
            ...this.employerBasicForm.employerBasic,
          }
          data.totalVictimsNum = this.totalVictimsNum
          data.contact = this.$zwxSm.sm4JsEncrypt(data.contact)
          data.legalPersonPhone = this.$zwxSm.sm4JsEncrypt(data.legalPersonPhone)
          data.managementPhone = this.$zwxSm.sm4JsEncrypt(data.managementPhone)
          data.idc = this.$zwxSm.sm4JsEncrypt(data.idc)
          data.mobileTel = this.$zwxSm.sm4JsEncrypt(data.mobileTel)
          // data.businessLicense = data.fileList[0].filePath
          // delete data.fileList
          data.tbEmployerHazardExposureExps = tbEmployerHazardExposureExps
          data.employerProductExpList = this.employerBasicForm.productList
          data.tsUserInfoExp = {
            userNo: data.userNo,
            userName: data.userName,
            mobileTel: data.mobileTel,
            idc: data.idc,
            uid: this.employerBasicForm.employerBasic.uid,
            cardType: this.employerBasicForm.employerBasic.cardType,
          }
          this.$emit('loading', true)
          this.$system.postJson(
            this.api + '/basic/info/saveEmployerBasic-1',
            data,
            true,
            true,
            data => {
              if (data.type == '00') {
                this.msEmployerBasicFormExp.msEmployerBasicFormJson = JSON.stringify(this.employerBasicForm)
                this.$system.notify('成功', data.mess, 'success')
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
              this.$emit('loading', false)
            },
            () => {
              this.$emit('loading', false)
              this.$system.notify('错误', '网络连接失败', 'error')
            }
          )
        }
      })
    },
    employerMapDialogSubmit(lng, lat, address) {
      this.employerBasicForm.employerBasic.lng = lng
      this.employerBasicForm.employerBasic.lat = lat
      this.employerBasicForm.employerBasic.workplaceAddr = address
      if (this.$zwxBase.verifyIsBlank(this.employerBasicForm.employerBasic.lng) && this.$zwxBase.verifyIsBlank(this.employerBasicForm.employerBasic.lat)) {
        this.employerBaiduMapParam.positionState = 0
      } else {
        this.employerBaiduMapParam.positionState = 1
      }
    },
    baiduMapMsNotify(tip, mess, result) {
      this.$system.notify(tip, mess, result)
    },
  },
}
</script>

<style lang="less" scoped>
.msmap {
  /deep/ .el-input {
    min-width: 468px !important;
  }
}
.ms-el-message {
  height: 28px;
  line-height: 28px;
  display: inline-block;
  min-width: 236px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
  padding: 0px 10px 0px 9px;
  border-radius: 2px;
}

.ms-el-message--warning {
  background-color: #fff5e6;
  border-color: #ffa738;
  color: #ff9400;
}
.organization {
  padding-left: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &-item {
    margin-top: 20px;
    margin-right: 20px;
    // width: 30%;
    width: 372px;
    height: 201px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    .title {
      padding: 12px;
      height: 60px;
      border-bottom: 1px solid #d5d9e0;
      &-unit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span:first-child {
          width: 288px;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #535e6f;
          line-height: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        span:last-child {
          padding: 3px 4px;
          font-size: 12px;
          font-weight: 400;
          color: #4276f0;
          background: #e8f5fc;
          border: 1px dashed #d2eaf7;
          border-radius: 2px;
        }
      }
    }
    .body {
      padding: 12px;
      padding-top: 5px;
      &-row {
        margin-top: 5px;
        display: flex;
        align-items: center;
        font-weight: 400;
        color: #595959;
        // line-height: 20px;
        img {
          margin-right: 5px;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
    }
  }
}
.employer-basic-management {
  min-width: 1440px;

  /deep/ .cascader-single-industry .zwx-input {
    width: 300px !important;
  }

  /deep/ .cascader-single-economic .zwx-input {
    width: 228px !important;
  }

  /deep/ .cascader-area-register-zone .zwx-input {
    width: 300px !important;
  }

  /deep/ .cascader-area-biz-zone .zwx-input {
    width: 300px !important;
  }

  .work-addr-position {
    display: inline-block;
    margin-right: 20px !important;
    margin-top: 14px !important;
    cursor: pointer;
  }

  .zwx-work-addr-input .el-input__suffix-inner {
    height: 30px !important;
  }

  .person-unit {
    display: inline-flex;
    width: 24px;
    height: 32px;
    background: #eaeff5;
    border: 1px solid #a8bbcf;
    border-radius: 0px 2px 2px 0px;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-left: -1px;
    font-size: 14px;
    color: #1f2d3d;
    line-height: 14px;
  }

  .div-table-row {
    display: flex;
    min-width: 1070px;
    width: 100%;
  }

  .div-table-head-name-col {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 38px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    border-top: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }

  .div-table-head-border {
    width: 10px;
    border-top: 1px solid #e1e5f4;
    border-right: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }

  .div-table-td-border {
    width: 10px;
    border-right: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }

  .div-table-td-name-col {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    padding: 8.5px 14px;
  }

  .div-table-td-num-col {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    padding: 5px 14px;
  }
}
</style>

<style lang="less"></style>
